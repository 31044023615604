import {PropsWithChildren} from 'react'
import classNames from 'classnames'

import styles from './Card.module.scss'

interface ICardProps {
  className?: string
  withShadow?: boolean
}

const Card = ({className, withShadow = false, children}: PropsWithChildren<ICardProps>) => (
  <div className={classNames(styles.card, className, {[styles.shadow]: withShadow})}>
    {children}
  </div>
)

export default Card
