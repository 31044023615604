export const PATHS = {
  HOME: '/',
  ITEM: '/item',
  PORTFOLIO: '/portfolio',
  RECENT_TRANSACTIONS: '/recent-transactions',
  PROFILE: '/profile',
  INVESTMENT: '/investment',
  PERSONAL_INFORMATION: '/personal-information',
  ADD_FUNDS: '/add-funds',
  FUNDS_WITHDRAWAL: '/funds-withdrawal',
  STRIPE: '/payment-method/stripe',
  BINANCE_PAY: '/payment-method/binance-pay',
}
