import {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate, useParams} from 'react-router-dom'
import copyToClipboard from 'copy-to-clipboard'

// import {ReactComponent as Heart} from '../../assets/icons/heart.svg'
import {ReactComponent as Share} from '../../assets/icons/share.svg'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {useUser} from '../../contexts/UserContext'
import {useWallet} from '../../contexts/WalletContext'
import {projectApi} from '../../services'
import {useProjectSlice} from '../../store/slices/project'

import Info from './components/Info/Info'
import Navigator from './components/Navigator/Navigator'

import styles from './Item.module.scss'

const Item = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {user} = useUser()
  const {connectWallet} = useWallet()
  const {itemId} = useParams<string>()
  const {
    setProject,
    id,
    name,
    address: {city, country, latitude, longitude},
    assets,
  } = useProjectSlice()

  const getProject = useCallback(
    async (id: number) => {
      try {
        const project = await projectApi.getProject(id)
        setProject(id, project)
      } catch ({response: {status}}: any) {
        if (status === 404) navigate(PATHS.HOME)
      }
    },
    [itemId],
  )

  const handleCopy = () => {
    copyToClipboard(window.location.href)
  }

  useEffect(() => {
    !!itemId && getProject(+itemId)
  }, [itemId, id])

  if (!user) {
    navigate(PATHS.HOME)
    connectWallet()
    return null
  }

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <div className={styles.title}>
            <span className={styles.name}>{(!!name && name) || <Skeleton width="40%" />}</span>
            <span className={styles.location}>
              {!!city && !!country ? `${city} - ${country}` : <Skeleton width="30%" />}
            </span>
          </div>
          <div className={styles.actions}>
            {(!!id && (
              <div className={styles.action} onClick={handleCopy}>
                <Share /> <span>{t('share')}</span>
              </div>
            )) || <Skeleton width={100} />}
            {/* <div className={styles.action}>
                  <Heart /> <span>{t('save')}</span>
                </div> */}
          </div>
        </div>

        <Navigator assets={assets} center={{lat: +latitude, lng: +longitude}} />
        <Info />
      </BannerContainer>
    </BannerSection>
  )
}

export default Item
