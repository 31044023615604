import ActionList from '../../components/ActionList/ActionList'
import FeaturedItems from '../../components/FeaturedItems/FeaturedItems'
import ItemList from '../../components/ItemList/ItemList'
// import Search from '../../components/Search/Search'
import WelcomeBanner from '../../components/WelcomeBanner/WelcomeBanner'

const Home = () => (
  <>
    <WelcomeBanner />
    {/* <Search /> */}
    <FeaturedItems />
    <ItemList />
    <ActionList />
  </>
)

export default Home
