import {useEffect, useState} from 'react'
import {FieldErrors, FormProvider, SubmitErrorHandler, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import Button from '../../../components/Button/Button'
import Checkbox from '../../../components/Checkbox/Checkbox'
import TextInput from '../../../components/TextInput/TextInput'
import {PATHS} from '../../../constants/paths'
import * as personalInformationForm from '../../../constants/personalInformationForm'
import {IPersonalInformationForm} from '../../../constants/personalInformationForm'
import {REG_EX} from '../../../constants/regExpression'
import {customerApi} from '../../../services'
import {useCustomerSlice} from '../../../store/slices/customer'

import styles from './PersonalInformation.module.scss'

interface IPersonalInformation extends WithTranslation {
  className?: string
  onlyEssentialData?: boolean
}
const PersonalInformation = ({className, onlyEssentialData, t}: IPersonalInformation) => {
  const navigate = useNavigate()
  const {
    customer_info: {id, first_name, last_name, email, birth_date, address},
    setEssentialData,
  } = useCustomerSlice()
  const methods = useForm<IPersonalInformationForm>({
    defaultValues: {
      name: first_name || '',
      surname: last_name || '',
      mail: email || '',
      birthdate: birth_date || '',
      mobile: '',
      street: address?.street,
      number: address?.number,
      postalCode: address?.cp,
      city: address?.city,
      country: address?.country,
      notPoliticallyExposed: false,
      notUsResident: false,
    },
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const onSubmit = async (data: IPersonalInformationForm) => {
    try {
      setSubmitting(true)
      const responseData = await customerApi.modifyCustomerInfo(id || 0, {
        first_name: data.name,
        last_name: data.surname,
        email: data.mail,
        birth_date: data.birthdate,
      })
      setEssentialData({...responseData})
      setSubmitting(false)
      navigate(PATHS.HOME)
    } catch (error: any) {
      console.log('Error on put data', error)
    } finally {
      setSubmitting(false)
    }
  }
  const onSubmitError = (
    errorData: SubmitErrorHandler<IPersonalInformationForm> | FieldErrors<IPersonalInformationForm>,
  ) => {
    console.log('PIForm-ErrorData', errorData)
  }

  useEffect(() => {
    if (
      !!first_name &&
      !!last_name &&
      (!methods.getValues('name') || !methods.getValues('surname'))
    )
      methods.reset({name: first_name, surname: last_name, birthdate: birth_date, mail: email})
  }, [first_name, last_name, methods])

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <span className={styles.title}>{t('personalInformation.title')}</span>
        <span className={styles.clarification}>{t('personalInformation.clarification')}</span>
      </div>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}>
          <div className={styles.subForm}>
            {!onlyEssentialData && (
              <span className={styles.title}>{t('personalInformation.personalDetails')}</span>
            )}
            <div className={styles.row}>
              <TextInput
                label={`${t(`user.${personalInformationForm.NAME}`)}`}
                name={personalInformationForm.NAME}
                withUseForm
                withBorder
                required
              />
              <TextInput
                label={`${t(`user.${personalInformationForm.SURNAME}`)}`}
                name={personalInformationForm.SURNAME}
                withUseForm
                withBorder
                required
              />
            </div>
            <TextInput
              label={`${t(`user.${personalInformationForm.BIRTHDATE}`)}`}
              name={personalInformationForm.BIRTHDATE}
              type="date"
              withUseForm
              withBorder
              required
            />
            {onlyEssentialData && (
              <TextInput
                label={`${t(`user.${personalInformationForm.MAIL}`)}`}
                name={personalInformationForm.MAIL}
                pattern={REG_EX.EMAIL}
                withUseForm
                withBorder
                required
              />
            )}
          </div>
          {!onlyEssentialData && (
            <div className={styles.subForm}>
              <span className={styles.title}>{t('personalInformation.contactInformation')}</span>
              <div className={styles.row}>
                <TextInput
                  label={`${t(`user.${personalInformationForm.MAIL}`)}`}
                  name={personalInformationForm.MAIL}
                  pattern={REG_EX.EMAIL}
                  withUseForm
                  withBorder
                  required={!onlyEssentialData}
                />
                <TextInput
                  label={`${t(`user.${personalInformationForm.MOBILE}`)}`}
                  name={personalInformationForm.MOBILE}
                  withUseForm
                  withBorder
                  required={!onlyEssentialData}
                />
              </div>
            </div>
          )}
          {!onlyEssentialData && (
            <div className={styles.subForm}>
              <span className={styles.title}>{t('personalInformation.address')}</span>
              <span className={styles.clarification}>
                {t('personalInformation.addressClarification')}
              </span>
              <TextInput
                label={`${t(`user.${personalInformationForm.STREET}`)}`}
                name={personalInformationForm.STREET}
                withUseForm
                withBorder
                required={!onlyEssentialData}
              />
              <div className={styles.row}>
                <TextInput
                  label={`${t(`user.${personalInformationForm.NUMBER}`)}`}
                  name={personalInformationForm.NUMBER}
                  withUseForm
                  withBorder
                  required
                />
                <TextInput
                  label={`${t(`user.${personalInformationForm.POSTAL_CODE}`)}`}
                  name={personalInformationForm.POSTAL_CODE}
                  withUseForm
                  withBorder
                  required={!onlyEssentialData}
                />
              </div>
              <div className={styles.row}>
                <TextInput
                  label={`${t(`user.${personalInformationForm.CITY}`)}`}
                  name={personalInformationForm.CITY}
                  withUseForm
                  withBorder
                  required={!onlyEssentialData}
                />
                <TextInput
                  label={`${t(`user.${personalInformationForm.COUNTRY}`)}`}
                  name={personalInformationForm.COUNTRY}
                  withUseForm
                  withBorder
                  required={!onlyEssentialData}
                />
              </div>
            </div>
          )}
          <div className={styles.checkboxsContainer}>
            <Checkbox
              filledColor="secondary-light"
              label={`${t(`user.${personalInformationForm.POLITICALLY_EXPOSED_CHECKBOX}`)}`}
              name={personalInformationForm.POLITICALLY_EXPOSED_CHECKBOX}
              withUseForm
              required
            />
            <Checkbox
              filledColor="secondary-light"
              label={`${t(`user.${personalInformationForm.US_RESIDENT_CHECKBOX}`)}`}
              name={personalInformationForm.US_RESIDENT_CHECKBOX}
              withUseForm
              required
            />
          </div>
          <Button
            className={styles.button}
            styledType="filled"
            filledColor="primary"
            type="submit"
            disabled={!methods.formState.isValid}
            loading={submitting}>
            {t('continue')}
          </Button>
        </form>
      </FormProvider>
    </div>
  )
}

export default withTranslation()(PersonalInformation)
