import {useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as ArrowTrendingUp} from '../../assets/icons/arrow-trending-up.svg'
import {ReactComponent as Building} from '../../assets/icons/building.svg'
import {ReactComponent as PieChart} from '../../assets/icons/pie-chart.svg'
import {ReactComponent as TokenWallet} from '../../assets/icons/token-wallet.svg'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import Card from '../Card/Card'

import ActionCard from './components/ActionCard/ActionCard'

import styles from './ActionList.module.scss'

export interface actionModel {
  actionKey: string
  descriptionKey: string
  icon: React.ReactElement
}

const actions = [
  {
    actionKey: 'action.search',
    descriptionKey: 'action.searchDescription',
    icon: <Building />,
  },
  {
    actionKey: 'action.tokenization',
    descriptionKey: 'action.tokenizationDescription',
    icon: <TokenWallet />,
  },
  {
    actionKey: 'action.performance',
    descriptionKey: 'action.performanceDescription',
    icon: <ArrowTrendingUp />,
  },
  {
    actionKey: 'action.lastSale',
    descriptionKey: 'action.lastSaleDescription',
    icon: <PieChart />,
  },
]

interface IActionList {
  design?: 'first' | 'second'
}

const ActionList = ({design = 'first'}: IActionList) => {
  const {t} = useTranslation()
  useEffect(() => {}, [t])
  return (
    <BannerSection className={classNames(styles.section, styles[design])}>
      <BannerContainer className={classNames(styles.container, styles[design])} layout="column">
        <div className={classNames(styles.header, styles[design])}>
          <span className={classNames(styles.title, styles[design])}>
            <Trans i18nKey="actionList.title">
              A simple, safe and <br />
              accessible investment
            </Trans>
          </span>
        </div>
        {design === 'first' && (
          <div className={styles.actionRow}>
            {actions?.map((action: actionModel) => (
              <Card key={action.actionKey} className={styles.actionCard} withShadow>
                <ActionCard actionData={action} />
              </Card>
            ))}
          </div>
        )}
      </BannerContainer>
    </BannerSection>
  )
}

export default ActionList
