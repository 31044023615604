import styles from './LoadingSpinner.module.scss'

const LoadingSpinner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loadingSpinner} />
    </div>
  )
}

export default LoadingSpinner
