import React, {Dispatch, useContext, useEffect, useState} from 'react'

import {USDT_ABI} from '../constants/abis'
import {getProvider} from '../libs/provider'
import {formatNumber} from '../utils/formatNumber'
import {logout} from '../utils/logout'

import {useWeb3} from './Web3Context'

export const UserContext = React.createContext<any>([])

const USDT_ADDRESS = process.env.REACT_APP_USDT_ADDRESS as string

export const UserProvider = ({children}: any) => {
  const {web3, setWeb3} = useWeb3()
  const [user, setUser] = useState<string | undefined>(localStorage.getItem('user') || undefined)
  const [balance, setBalance] = useState<string>('0')
  const [fetchingBalance, setFetchingBalance] = useState<boolean>(true)

  const handleUserOnPageLoad = async () => {
    const provider = await getProvider()
    const accounts = await provider.request({method: 'eth_accounts'})
    // If user wallet is no longer connected, logout
    if (!accounts[0] && user) {
      logout(setWeb3, setUser)
    }
    // Set user in localStorage, or clear localStorage if no wallet connected
    accounts[0] ? localStorage.setItem('user', accounts[0]) : localStorage.removeItem('user')
    setUser(accounts[0])
  }

  const getBalance = async () => {
    try {
      setFetchingBalance(true)
      if (!!user && !!web3) {
        const contract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS)
        const weiBalance = await contract.methods.balanceOf(user).call()
        setBalance(formatNumber(+web3.utils.fromWei(weiBalance)))
      }
    } catch (error) {
      console.error('Error getting balance', error)
    } finally {
      setFetchingBalance(false)
    }
  }

  const value = React.useMemo(
    () => ({
      user,
      setUser,
      getBalance,
      balance,
      fetchingBalance,
    }),
    [user, setUser, getBalance, balance],
  )

  useEffect(() => {
    handleUserOnPageLoad()
  }, [])

  return <UserContext.Provider value={{...value}}>{children}</UserContext.Provider>
}

export const useUser = (): {
  user: string | undefined
  setUser: Dispatch<string>
  getBalance: Dispatch<void>
  balance: string
  fetchingBalance: boolean
} => {
  return useContext(UserContext)
}
