import DestinationAddress from './DestinationAddress/DestinationAddress'
import FundAmount from './FundAmount/FundAmount'
import WithdrawalConfirmation from './WithdrawalConfirmation/WithdrawalConfirmation'

const withdrawalSteps = [
  <FundAmount key={0} />,
  <DestinationAddress key={1} />,
  <WithdrawalConfirmation key={2} />,
]

export default withdrawalSteps
