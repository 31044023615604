import {ReactComponent as Success} from '../../../assets/icons/circle-checkmark.svg'
import {ReactComponent as User} from '../../../assets/icons/user.svg'
import {ReactComponent as Wallet} from '../../../assets/icons/wallet.svg'
import {IStep} from '../../../components/Stepper/types/Step'

import Kyc from './Kyc/Kyc'
import PaymentConfirmation from './PaymentConfirmation/PaymentConfirmation'
import PaymentMethod from './PaymentMethod/PaymentMethod'

export const steps: IStep[] = [
  {
    number: 1,
    component: <Kyc />,
    icon: <User />,
    labelKey: 'kyc',
  },
  {
    number: 2,
    component: <PaymentMethod />,
    icon: <Wallet />,
    labelKey: 'paymentMethod',
  },
  {
    number: 3,
    component: <PaymentConfirmation />,
    icon: <Success />,
    labelKey: 'paymentConfirmation',
  },
]
