import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as UploadFileIcon} from '../../assets/icons/upload-file.svg'
import {ReactComponent as UploadFileSuccessIcon} from '../../assets/icons/upload-file-success.svg'
import Card from '../Card/Card'

import styles from './UploadFile.module.scss'

interface IUploadFile extends WithTranslation {
  className?: string
  maxSize?: number
  title: string | null
  allowedFormats: string[]
  externalErrorMessage?: string | null
  isSuccess?: boolean
  onFileUploaded: (file: any) => void
}

const UploadFile = ({
  className,
  allowedFormats,
  maxSize = 5, // mb
  title,
  isSuccess,
  externalErrorMessage,
  onFileUploaded,
  t,
}: IUploadFile) => {
  const [fileName, setFileName] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string | null>()
  const parseFormatsLabel = useCallback(
    (formats: string[]): string => {
      return formats.map(format => format.split('.').join('').toLocaleUpperCase()).join('/')
    },
    [allowedFormats],
  )
  const parseAccept = useCallback(
    (formats: string[]): string => {
      return formats.map(format => `.${format.split('.').join('')}`.toLocaleLowerCase()).join(',')
    },
    [allowedFormats],
  )
  const onUploaded = useCallback(
    (file: File) => {
      setFileName((!!file && file.name) || '')
      if (!!file) {
        if (file.size / 1024 / 1024 > maxSize) {
          setErrorMessage(`${t('uploadFile.maxSizeError')} ${maxSize} mb`)
          return
        } else !!errorMessage && setErrorMessage(null)

        const formData = new FormData()
        formData.append('file', file, file.name)
        formData.append('name', 'file')
        formData.append('filename', 'file')
        onFileUploaded(formData)
      } else {
        onFileUploaded(file)
        setErrorMessage(null)
      }
    },
    [allowedFormats, maxSize, fileName, errorMessage],
  )

  useEffect(() => {
    if (errorMessage || externalErrorMessage) setFileName('')
  }, [errorMessage, externalErrorMessage])

  return (
    <div className={styles.container}>
      <Card
        className={classNames(styles.card, className, {
          [styles.error]: !!errorMessage || !!externalErrorMessage,
        })}
        withShadow>
        <div
          className={styles.uploader}
          onClick={() => document.getElementById('uploadFile')?.click()}>
          <input
            id="uploadFile"
            type="file"
            accept={parseAccept(allowedFormats)}
            style={{display: 'none'}}
            onChange={(event: any) => onUploaded(event.target?.files[0])}
          />
          {(isSuccess && <UploadFileSuccessIcon />) || <UploadFileIcon />}
          <div className={styles.text}>
            {title && (
              <span className={styles.title}>{isSuccess && fileName ? fileName : title}</span>
            )}
            <span className={styles.format}>{`${t('uploadFile.format')} ${parseFormatsLabel(
              allowedFormats,
            )} (Max. ${maxSize} mb)`}</span>
          </div>
        </div>
      </Card>
      <span
        className={classNames(styles.errorMessage, {
          [styles.active]: !!errorMessage || !!externalErrorMessage,
        })}>
        {errorMessage || externalErrorMessage}
      </span>
    </div>
  )
}

export default withTranslation()(UploadFile)
