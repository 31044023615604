import {create, StateCreator} from 'zustand'

import {IStep} from '../../components/Stepper/types/Step'
import {steps} from '../../pages/investment/steps/constant'
import {getInvestmentStorage, saveInvestmentStorage} from '../localStorage/investment'

export interface StepperSlice {
  steps: IStep[]
  activeStep: IStep
  activeItemId: number
  nextStep: () => void
  lastStep: () => void
  setActiveItemId: (itemId: number) => void
  setActiveStep: (active: IStep) => void
  setSuccessfulStep: (step: IStep) => void
  reset: () => void
}

const getActiveStepByNumber = (number: number): IStep =>
  ({
    ...steps.filter(step => step.number === number)[0],
    isSuccessful: !!getInvestmentStorage()?.isPaymentSuccessful,
  } as IStep)

const createStepperSlice: StateCreator<StepperSlice> = (set, get) => ({
  steps: steps,
  activeStep: getActiveStepByNumber(getInvestmentStorage()?.activeStepNumber || 1),
  activeItemId: 0,
  setActiveItemId: (itemId: number) => set(() => ({activeItemId: itemId})),
  setActiveStep: (active: IStep) => {
    saveInvestmentStorage({activeStepNumber: active.number})
    set(() => ({activeStep: active}))
  },
  nextStep: () => {
    const {activeStep, steps} = get()
    const nextStep =
      activeStep.number < steps.length ? getActiveStepByNumber(activeStep.number + 1) : activeStep
    saveInvestmentStorage({activeStepNumber: nextStep.number})
    set({
      activeStep: nextStep,
    })
  },
  lastStep: () => {
    const {activeStep} = get()
    const lastStep =
      activeStep.number > 1 ? getActiveStepByNumber(activeStep.number - 1) : activeStep
    saveInvestmentStorage({activeStepNumber: lastStep.number})
    set({
      activeStep: lastStep,
    })
  },
  setSuccessfulStep: (successfulStep: IStep) => {
    saveInvestmentStorage({isPaymentSuccessful: true})
    set(state => ({
      steps: state.steps.map(step =>
        step.number === successfulStep.number ? ({...step, isSuccessful: true} as IStep) : step,
      ),
    }))
  },
  reset: () => set({steps: steps, activeStep: steps[0], activeItemId: 0}),
})

export const useStepperSlice = create<StepperSlice>()((...a) => ({
  ...createStepperSlice(...a),
}))
