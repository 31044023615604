import {useCallback} from 'react'
import {isMobile} from 'react-device-detect'
import {useFormContext} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as Copy} from '../../../../assets/icons/copy.svg'
import {ReactComponent as QRScan} from '../../../../assets/icons/qr-scan.svg'
import Button from '../../../../components/Button/Button'
import InfoPanel from '../../../../components/InfoPanel/InfoPanel'
import TextInput from '../../../../components/TextInput/TextInput'
import {REG_EX} from '../../../../constants/regExpression'
import {FundsWithdrawalForm} from '../../FundsWithdrawal'

import styles from '../WithdrawalSteps.module.scss'

const DESTINATION_ADDRESS = 'destinationAddress'
const network = 'POLYGON'

const DestinationAddress = ({t}: WithTranslation) => {
  const {setValue, trigger} = useFormContext<FundsWithdrawalForm>()
  const paste = useCallback(async () => {
    const copiedAddress = await navigator.clipboard.readText()
    setValue('destinationAddress', copiedAddress)
    trigger()
  }, [])
  const scan = useCallback(() => {}, [])

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>{t('fundsWithdrawal.whichWalletDoYouWant')}</div>
      </div>
      <div className={styles.body}>
        <InfoPanel
          type="info"
          label="Verifica la red de tu dirección antes de continuar"
          description="Si no seleccionas correctamente tu red, podrías
        enviarles tus criptomonedas a otra persona y perderlas"
        />
        <TextInput
          label={`${t(`fundsWithdrawal.address`)} ${network}`}
          name={DESTINATION_ADDRESS}
          pattern={REG_EX.ADDRESS}
          withUseForm
          withBorder
          required
        />
        <div className={styles.buttons}>
          <Button
            className={styles.actionButton}
            styledType="outline"
            onClick={() => {
              paste()
            }}>
            <Copy /> {t('paste')}
          </Button>
          {isMobile && (
            <Button
              className={styles.actionButton}
              styledType="outline"
              onClick={() => {
                scan()
              }}>
              <QRScan /> {t('scan')}
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default withTranslation()(DestinationAddress)
