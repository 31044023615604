import axios from '../../core/axios'
import {BRAND, BRAND_KEY} from '../../utils/brand'
import {ICustomerInfo} from '../interfaces/ICustomerInfo'
import {IOwnershipInfo} from '../interfaces/IOwnershipInfo'

export interface ICustomerData {
  customer_info: ICustomerInfo
  customerTokens?: IOwnershipInfo[]
}
export interface IInvested {
  tokens: number
  currency: string
  amount: number
  projects: number
}

export default {
  getCustomerData: async (): Promise<ICustomerData> => {
    const {data} = await axios.get('self')
    const customer_info = data.customer_info as ICustomerInfo
    return {customer_info} as ICustomerData
  },
  modifyCustomerInfo: async (customerId: number, data: ICustomerInfo): Promise<ICustomerInfo> => {
    const {data: responseData} = await axios.put(`customers/${customerId}/`, data)
    return responseData as ICustomerInfo
  },
  getIdentityProviderUrl: (customerId: number) =>
    axios.post('identity/start', {
      userId: customerId,
    }),
  getOwnershipInfo: async (): Promise<IOwnershipInfo[]> => {
    try {
      const {data: responseData} = await axios.get(
        `customers/ownership/${(BRAND !== BRAND_KEY.criptokuantica && '?brand=' + BRAND) || ''}`,
      )
      if (Array.isArray(responseData)) return responseData
      else return [responseData]
    } catch (error: any) {
      if (error?.response?.status === 404) return []
      throw Error(error?.message)
    }
  },
  getInvested: async (): Promise<IInvested> => {
    const {data: responseData} = await axios.get(
      `customers/invested/${(BRAND !== BRAND_KEY.criptokuantica && '?brand=' + BRAND) || ''}`,
    )
    return responseData as IInvested
  },
  withdraw: (destinationAddress: string, amount: number) =>
    axios.post('customers/withdraw/', {
      destination_address: destinationAddress,
      amount,
    }),
}
