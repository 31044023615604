import {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Edit} from '../../../../assets/icons/edit.svg'
import {IPersonalInformationBaseForm} from '../../../../constants/personalInformationForm'
import * as personalInformationForm from '../../../../constants/personalInformationForm'
import {customerApi} from '../../../../services'
import {useCustomerSlice} from '../../../../store/slices/customer'

import styles from './PersonalInformationEditor.module.scss'

const PersonalInformationEditor = ({t}: WithTranslation) => {
  const [isEditing, setEditing] = useState<boolean>(false)
  const {
    customer_info: {id, first_name, last_name, email},
    setEssentialData,
  } = useCustomerSlice()

  const {handleSubmit, setValue, setFocus, control} = useForm<IPersonalInformationBaseForm>({
    defaultValues: {
      name: first_name,
      surname: last_name,
      mail: email,
    },
  })

  const submitForm = async (data: IPersonalInformationBaseForm) => {
    if (isEditing) {
      const responseData = await customerApi.modifyCustomerInfo(id || 0, {
        email: data.mail,
      })
      setEssentialData({...responseData})
    }
    setEditing(!isEditing)
  }

  const allowedEdition = (
    fieldName:
      | typeof personalInformationForm.NAME
      | typeof personalInformationForm.SURNAME
      | typeof personalInformationForm.MAIL,
  ) => fieldName === 'mail'

  useEffect(() => {
    if (!!first_name && !!last_name && !!email) {
      setValue('name', first_name)
      setValue('surname', last_name)
      setValue('mail', email)
    }
  }, [first_name, last_name, email])

  return (
    <div className={styles.personalInformation}>
      <form onSubmit={handleSubmit(submitForm)} className={styles.form}>
        <div className={styles.header}>
          <span>{t('profile.profile')}</span>
          <button className={classNames(styles.button, {[styles.active]: isEditing})} type="submit">
            {isEditing ? t('confirm') : t('edit')}
          </button>
        </div>
        <div className={styles.body}>
          <Controller
            control={control}
            name={personalInformationForm.NAME}
            // rules={{
            //   required: {value: true, message: t('')},
            // }}
            render={({field: {name, ...rest}}) => (
              <div className={styles.inputContainer}>
                <div className={styles.label}>{t(`user.${name}`)}</div>
                <div className={styles.box}>
                  <input
                    disabled={(allowedEdition(name) && !isEditing) || !allowedEdition(name)}
                    className={styles.input}
                    required
                    {...rest}
                  />
                </div>
              </div>
            )}
          />
          <Controller
            control={control}
            name={personalInformationForm.SURNAME}
            // rules={{
            //   required: {value: true, message: t('')},
            // }}
            render={({field: {name, ...rest}}) => (
              <div className={styles.inputContainer}>
                <div className={styles.label}>{t(`user.${name}`)}</div>
                <div className={styles.box}>
                  <input
                    disabled={(allowedEdition(name) && !isEditing) || !allowedEdition(name)}
                    className={styles.input}
                    required
                    {...rest}
                  />{' '}
                </div>
              </div>
            )}
          />
          <Controller
            control={control}
            name={personalInformationForm.MAIL}
            // rules={{
            //   required: {value: true, message: t('')},
            // }}
            render={({field: {name, ...rest}}) => (
              <div className={styles.inputContainer}>
                <div className={styles.label}>{t(`user.${name}`)}</div>
                <div className={styles.box}>
                  <input
                    disabled={(allowedEdition(name) && !isEditing) || !allowedEdition(name)}
                    className={styles.input}
                    required
                    {...rest}
                  />
                  <Edit
                    className={classNames({[styles.active]: isEditing})}
                    onClick={() => isEditing && setFocus(name)}
                  />
                </div>
              </div>
            )}
          />
        </div>
      </form>
    </div>
  )
}
export default withTranslation()(PersonalInformationEditor)
