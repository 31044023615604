import {CURRENCY} from '../../constants/currencies'

export enum PAYMENT_METHOD {
  bank_transfer = 'bank_transfer',
  binance_pay = 'binance_pay',
  wallet = 'wallet',
  credit_card = 'credit_card',
}

export type PaymentMethod = keyof typeof PAYMENT_METHOD

export interface IToken {
  id: number
  created_at: string
  modified_at: string
  name: string
  price: string
  tx_hash: string
  ipfs: string
  total_supply: number
  available_stock: number
  reserved_stock: number
  payment_methods: PaymentMethod[]
  currency: keyof typeof CURRENCY
  nft_contract_address: string
}
