import {polygon, polygonMumbai} from '@wagmi/chains'
import {EthNetworkConfiguration, Magic} from 'magic-sdk'

import Config from '../utils/config'
import {Networks} from '../utils/networks'

export const formattedNetwork = (): EthNetworkConfiguration => {
  const network = localStorage.getItem('network')
  switch (network) {
    case Networks.Optimism:
      return {
        rpcUrl: process.env.REACT_APP_OPTIMISM_RPC_URL as string,
        chainId: 420,
      }
    case Networks.Polygon:
      return {
        rpcUrl: process.env.REACT_APP_POLYGON_RPC_URL as string,
        chainId: 80001,
      }
    case Networks.Goerli:
      return {
        rpcUrl: process.env.REACT_APP_GOERLI_RPC_URL as string,
        chainId: 5,
      }
    default:
      return {
        rpcUrl: process.env.REACT_APP_SEPOLIA_RPC_URL as string,
        chainId: 11155111,
      }
  }
}

export const magic = new Magic(process.env.REACT_APP_MAGIC_API_KEY as string, {
  // network: formattedNetwork(),
  network: {
    rpcUrl: Config.isProduction
      ? polygon.rpcUrls.default.http[0]
      : 'https://polygon-mumbai.g.alchemy.com/v2/cd6fSNOx4aBs5zIwlqUTr9MKa8Dr-66p',
    // rpcUrl: polygonMumbai.rpcUrls.default.http[0],
    // chainId: polygonMumbai.id,
    // rpcUrl: polygon.rpcUrls.default.http[0],
    chainId: Config.isProduction ? polygon.id : polygonMumbai.id,
  },
})
