import {useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {customerApi} from '../../../../services'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {useStepperSlice} from '../../../../store/slices/stepper'

import styles from './Kyc.module.scss'

const Kyc = () => {
  const {nextStep} = useStepperSlice()
  const {t} = useTranslation()
  const button = useRef(null)
  const {
    customer_info: {id},
  } = useCustomerSlice()
  const [clientid] = useState(process.env.REACT_APP_METAMAP_CLIENT_ID || '')
  const [flowid] = useState(process.env.REACT_APP_METAMAP_FLOW_ID || '')
  const [metamapSrc, setMetamapSrc] = useState('')

  useEffect(() => {
    if (button?.current) {
      button.current.addEventListener('metamap:userFinishedSdk', () => {
        nextStep()
      })
      button.current.addEventListener('metamap:exitedSdk', () => {
        window.location.reload()
      })

      return () => {
        button?.current?.removeEventListener('metamap:userFinishedSdk', () => {})
        button?.current?.removeEventListener('metamap:exitedSdk', () => {})
      }
    }
  }, [button])

  const getIdentityProviderUrl = useCallback(async () => {
    const {
      data: {url},
    } = await customerApi.getIdentityProviderUrl(id)
    setMetamapSrc(url)
  }, [id, metamapSrc])

  useEffect(() => {
    if (!metamapSrc && !!id) getIdentityProviderUrl()
  }, [metamapSrc, id])

  return (
    <div className={styles.container}>
      <span className={styles.label}>{t('metamap.description')}</span>
      <div className={styles.blank}>
        <div className={styles.card} />
      </div>
      <iframe
        id="metamapIframe"
        title="Metamap Widget"
        className={styles.metamap}
        src={metamapSrc}
      />
      <metamap-button ref={button} clientid={clientid} flowId={flowid} />
    </div>
  )
}

export default Kyc
