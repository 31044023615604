import {useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../../../../../assets/icons/arrow-next.svg'
import Button from '../../../../../../components/Button/Button'
import {CURRENCY, currentCurrency} from '../../../../../../constants/currencies'
import {PAYMENT_METHOD} from '../../../../../../services/interfaces/IToken'
import {
  getInvestmentStorage,
  saveInvestmentStorage,
} from '../../../../../../store/localStorage/investment'
import {TOKEN_AMOUNT_INDEX, useCounterSlice} from '../../../../../../store/slices/counter'
import {usePaymentMethodSlice} from '../../../../../../store/slices/paymentMethod'
import {useProjectSlice} from '../../../../../../store/slices/project'
import {useRatesSlice} from '../../../../../../store/slices/rates'
import {useStepperSlice} from '../../../../../../store/slices/stepper'
import {formatNumber} from '../../../../../../utils/formatNumber'
import {foundRate} from '../../../../../../utils/rates'

import styles from './Confirmation.module.scss'

interface IConfirmationProps extends WithTranslation {
  isPaymentLoading: boolean
  isApproved: boolean
  endConfirmation: () => void
}

const Confirmation = ({t, isPaymentLoading, isApproved, endConfirmation}: IConfirmationProps) => {
  const {quantity} = useCounterSlice()
  const {activePaymentMethod} = usePaymentMethodSlice()
  const {
    name,
    tokenomic: {
      token: {id: tokenId, price, currency},
    },
  } = useProjectSlice()
  const {lastStep} = useStepperSlice()
  const {rates, fetchRates} = useRatesSlice()
  const [rate, setRate] = useState<number>()
  const amountOfTokens =
    getInvestmentStorage()?.amountOfTokens || quantity[`${TOKEN_AMOUNT_INDEX}${tokenId}`]

  const totalPrice = amountOfTokens * +price

  useEffect(() => {
    if (!!rate || !rates.length || !currency) return
    const rateFounded = foundRate(rates, currency, CURRENCY.USDT)
    setRate((!!rateFounded && rateFounded) || 1)
  }, [rates, currency, rate])

  useEffect(() => {
    fetchRates()
  }, [])

  return (
    <>
      <span className={styles.title}>{t('investment.paymentConfirmation.confirmPayment')}</span>
      <div className={styles.price}>
        <span>
          {!!price && (
            <div className={styles.value}>
              {formatNumber(
                activePaymentMethod?.type === PAYMENT_METHOD.credit_card
                  ? totalPrice
                  : (rate && totalPrice * rate) || totalPrice,
              )}
            </div>
          )}
          <div className={styles.currency}>
            {activePaymentMethod?.type === PAYMENT_METHOD.credit_card
              ? currency || currentCurrency
              : CURRENCY.USDT}
          </div>
        </span>
        {activePaymentMethod?.type !== PAYMENT_METHOD.credit_card && (
          <div className={styles.officialCurrency}>{`≈ ${totalPrice} ${
            currency || currentCurrency
          }`}</div>
        )}
      </div>
      <div className={styles.properties}>
        <div className={styles.property}>
          {t('investment.paymentConfirmation.tokenName')}
          <span>{name}</span>
        </div>
        <div className={styles.property}>
          {t('investment.paymentConfirmation.tokensAmount')}
          <span>{amountOfTokens}</span>
        </div>
      </div>
      <div className={styles.paymentMethod}>
        <div className={styles.label}>{t('investment.paymentConfirmation.paymentMethod')}</div>
        <div className={styles.method}>
          {activePaymentMethod && (
            <span>
              <div className={classNames(styles.circle, activePaymentMethod.iconClassName)}>
                {activePaymentMethod.icon}
              </div>
              {t(`investment.paymentMethod.${activePaymentMethod.key}`)}
            </span>
          )}
          <div
            className={styles.changeMethod}
            onClick={() => {
              lastStep()
              saveInvestmentStorage({
                activeSubStep: undefined,
              })
            }}>
            {t('investment.paymentConfirmation.changeMethod')}
            <Arrow />
          </div>
        </div>
      </div>
      <span className={styles.footer}>
        <div className={styles.terms}>
          {t('investment.paymentConfirmation.byClickingOn')}{' '}
          <span>{`"${t('acceptAndConfirm')}"`}</span>{' '}
          {t('investment.paymentConfirmation.yoAgreeTo')}{' '}
          <a
            className={styles.link}
            href={
              'https://ipfs.io/ipfs/bafkreihhub2yofrtepox6iek7bhglt3ma3xemfeqq3uq54xkt4firw7noq'
            }
            target="_blank"
            rel="noopener noreferrer">
            {t('investment.paymentConfirmation.termsOfService')}
          </a>{' '}
          {t('investment.paymentConfirmation.ofThePlatform')}
        </div>
      </span>
      <Button
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        loading={isPaymentLoading}
        onClick={() => endConfirmation()}>
        {isApproved ? t('acceptAndConfirm') : t('approve')}
      </Button>
    </>
  )
}

export default withTranslation()(Confirmation)
