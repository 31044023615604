import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'

import {ReactComponent as Arrow} from '../../../../../../assets/icons/arrow-next.svg'
import {ReactComponent as Success} from '../../../../../../assets/icons/success.svg'
import {ReactComponent as WhatsApp} from '../../../../../../assets/icons/whatsApp.svg'
import Button from '../../../../../../components/Button/Button'
import Card from '../../../../../../components/Card/Card'
import InfoPanel from '../../../../../../components/InfoPanel/InfoPanel'
import Modal from '../../../../../../components/Modal/Modal'
import UploadFile from '../../../../../../components/UploadFile/UploadFile'
import {CURRENCY, CURRENCY_SYMBOL, currentCurrency} from '../../../../../../constants/currencies'
import {PATHS} from '../../../../../../constants/paths'
import {saleApi} from '../../../../../../services'
import {IBankDetail} from '../../../../../../services/api/sale'
import {
  clearInvestmentStorage,
  getInvestmentStorage,
  saveInvestmentStorage,
} from '../../../../../../store/localStorage/investment'
import {TOKEN_AMOUNT_INDEX, useCounterSlice} from '../../../../../../store/slices/counter'
import {usePaymentMethodSlice} from '../../../../../../store/slices/paymentMethod'
import {useProjectSlice} from '../../../../../../store/slices/project'
import {useRatesSlice} from '../../../../../../store/slices/rates'
import {useStepperSlice} from '../../../../../../store/slices/stepper'
import {formatNumber} from '../../../../../../utils/formatNumber'
import {foundRate} from '../../../../../../utils/rates'

import styles from './Reserve.module.scss'

interface IPaymentMean {
  currency: keyof typeof CURRENCY | 'CASH'
  way: 'faceToFace' | 'transfer'
}
const paymentMeans: IPaymentMean[] = [
  {currency: 'USD', way: 'transfer'},
  {currency: 'ARS', way: 'transfer'},
  {currency: 'CASH', way: 'faceToFace'},
]

const Reserve = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const [isReserving, setReserving] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const [fileUrl, setFileUrl] = useState<string>('')
  const {quantity} = useCounterSlice()
  const {activePaymentMethod} = usePaymentMethodSlice()
  const [activePaymentMean, setActivePaymentMean] = useState<IPaymentMean>(paymentMeans[0])
  const [bankDetails, setBankDetails] = useState<IBankDetail[]>()
  const {
    name,
    contact_phone,
    tokenomic: {
      token: {id: tokenId, price, currency},
    },
  } = useProjectSlice()
  const {lastStep, reset} = useStepperSlice()
  const {rates, fetchRates} = useRatesSlice()
  const [currentRate, setRate] = useState<number>(1)
  const [uploadedSuccess, setUploadedSuccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()

  const amountOfTokens =
    getInvestmentStorage()?.amountOfTokens || quantity[`${TOKEN_AMOUNT_INDEX}${tokenId}`] || 1

  const endReserve = useCallback(async () => {
    try {
      if (!uploadedSuccess && activePaymentMean?.currency !== 'CASH') {
        setErrorMessage(t('investment.reserve.noFile'))
        return
      }
      setErrorMessage(null)
      setReserving(true)
      await saleApi.reserve(
        amountOfTokens,
        tokenId,
        activePaymentMean.currency,
        activePaymentMean.currency !== 'CASH' ? '' : fileUrl,
      )
      setSuccess(true)
    } catch (error) {
      console.log('Error on reserving', error)
    } finally {
      setReserving(false)
    }
  }, [
    amountOfTokens,
    tokenId,
    isReserving,
    isSuccess,
    activePaymentMean,
    uploadedSuccess,
    setFileUrl,
    fileUrl,
  ])

  const getBankDetails = useCallback(async () => {
    try {
      const bankDetailsData = await saleApi.getBankDetails(tokenId)
      setBankDetails(bankDetailsData)
    } catch (error) {
      console.log('Error on getting bankDetails', error)
    }
  }, [bankDetails, tokenId])

  const onFileUploaded = useCallback(
    async (file: FormData | any) => {
      try {
        if (errorMessage) setErrorMessage(null)
        if (!file) {
          setUploadedSuccess(false)
          setFileUrl('')
          return
        }
        const fileResponse = await saleApi.uploadFile(file)
        setFileUrl(fileResponse)
        setUploadedSuccess(true)
      } catch (error) {
        console.log('Error on uploading file', error)
        setErrorMessage(t('uploadFile.errorLoadingFile'))
      }
    },
    [uploadedSuccess, setUploadedSuccess, errorMessage, setErrorMessage, setFileUrl, fileUrl],
  )

  useEffect(() => {
    saveInvestmentStorage({activeSubStep: 'Reserve'})
    if (!bankDetails?.length) getBankDetails()
  }, [])

  useEffect(() => {
    fetchRates()
  }, [])

  useEffect(() => {
    if (!activePaymentMean || activePaymentMean?.currency === 'CASH' || !rates.length || !currency)
      return
    const rateFounded = foundRate(rates, currency, activePaymentMean.currency)
    setRate((!!rateFounded && rateFounded) || 1)
  }, [activePaymentMean])

  const cbu =
    (!!bankDetails?.length &&
      bankDetails.find(detail => detail.currency === activePaymentMean.currency)?.account_number) ||
    ''

  return (
    <>
      <span className={styles.title}>{t('investment.reserve.title')}</span>
      <div className={styles.price}>
        <span>
          <div className={styles.value}>{amountOfTokens}</div>
          <div className={styles.currency}>{name}</div>
        </span>
        <div className={styles.officialCurrency}>{`≈ ${formatNumber(amountOfTokens * +price)} ${
          CURRENCY_SYMBOL[currency || currentCurrency]
        }`}</div>
      </div>
      <div className={styles.body}>
        <div className={styles.properties}>
          <div className={styles.label}>{t('investment.reserve.paymentMean')}</div>
          <div className={styles.paymentMeansContainer}>
            {paymentMeans.map(paymentMean => (
              <Card key={paymentMean.currency} withShadow>
                <div
                  className={classNames(styles.paymentMean, {
                    [styles.selected]: paymentMean.currency === activePaymentMean.currency,
                  })}
                  onClick={() => setActivePaymentMean(paymentMean)}>
                  <div className={styles.box}>
                    {(paymentMean.currency === 'CASH' && t('investment.reserve.cash')) ||
                      paymentMean.currency}
                    <span>{t(`investment.reserve.${paymentMean.way}`)}</span>
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <div className={styles.propertyContainer}>
            {activePaymentMean.currency === 'CASH' && (
              <div className={styles.whatsApp}>
                <span>{t('whatsAppDescription')}</span>
                <a
                  className={styles.social}
                  href={`https://wa.me/${contact_phone}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <WhatsApp />
                </a>
              </div>
            )}
            {activePaymentMean.currency !== 'CASH' && (
              <>
                <div className={styles.property}>
                  {t(`investment.reserve.monetaryUnit.${activePaymentMean.currency.toLowerCase()}`)}
                  <span>
                    {formatNumber(amountOfTokens * +price * currentRate)}{' '}
                    {CURRENCY_SYMBOL[activePaymentMean.currency]}
                  </span>
                </div>
                <div className={styles.cbu}>
                  <div className={styles.text}>
                    {`${t('investment.reserve.cbu')} ${activePaymentMean.currency}`}{' '}
                    {cbu || <Skeleton width={150} />}
                  </div>
                  <span onClick={() => !!cbu && copyToClipboard(cbu)}>{t('copy')}</span>
                </div>
              </>
            )}
          </div>
        </div>
        {activePaymentMean?.currency !== 'CASH' && (
          <div className={styles.uploader}>
            <UploadFile
              title={t('investment.reserve.bankTransferReceipt')}
              allowedFormats={['pdf', 'jpg', 'png']}
              onFileUploaded={onFileUploaded}
              isSuccess={uploadedSuccess}
              externalErrorMessage={errorMessage}
            />
            {!uploadedSuccess && !errorMessage && (
              <InfoPanel description={t('investment.reserve.transferWarning')} type="warning" />
            )}
          </div>
        )}
        <div className={styles.paymentMethod}>
          <div className={styles.label}>{t('investment.reserve.paymentMethod')}</div>
          <div className={styles.method}>
            {activePaymentMethod && (
              <span>
                <div className={classNames(styles.circle, activePaymentMethod.iconClassName)}>
                  {activePaymentMethod.icon}
                </div>
                {t(`investment.paymentMethod.${activePaymentMethod.key}`)}
              </span>
            )}
            <div
              className={styles.changeMethod}
              onClick={() => {
                lastStep()
                saveInvestmentStorage({
                  activeSubStep: undefined,
                })
              }}>
              {t('investment.reserve.changeMethod')}
              <Arrow />
            </div>
          </div>
        </div>
        <span className={styles.footer}>
          <div className={styles.terms}>
            {t('investment.paymentConfirmation.byClickingOn')} <span>{`"${t('reserve')}"`}</span>{' '}
            {t('investment.paymentConfirmation.yoAgreeTo')}{' '}
            <a
              className={styles.link}
              href={
                'https://ipfs.io/ipfs/bafkreihhub2yofrtepox6iek7bhglt3ma3xemfeqq3uq54xkt4firw7noq'
              }
              target="_blank"
              rel="noopener noreferrer">
              {t('investment.paymentConfirmation.termsOfService')}
            </a>{' '}
            {t('investment.paymentConfirmation.ofThePlatform')}
          </div>
        </span>
      </div>
      <Button
        className={styles.button}
        styledType="filled"
        filledColor="primary"
        loading={isReserving}
        onClick={() => endReserve()}>
        {t('reserve')}
      </Button>

      <Modal
        visible={isSuccess}
        onClose={() => setSuccess(false)}
        closeButtonHidden
        outerClassName={styles.outerModal}
        containerClassName={styles.containerModal}
        icon={<Success />}
        title={t('investment.reserve.confirmedReservation')}
        subtitle={t('investment.reserve.timeToBeCanceled')}
        button={{
          label: t('continue'),
          onClick: () => {
            clearInvestmentStorage()
            reset()
            navigate(PATHS.PORTFOLIO)
          },
        }}>
        <span className={styles.description}>{`${t(
          'investment.reserve.contact',
        )} ${contact_phone}`}</span>
      </Modal>
    </>
  )
}

export default withTranslation()(Reserve)
