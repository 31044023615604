import {ReactNode} from 'react'

import {ReactComponent as Bank} from '../../../../../assets/icons/bank.svg'
import {ReactComponent as Binance} from '../../../../../assets/icons/binance.svg'
import {ReactComponent as CreditCard} from '../../../../../assets/icons/credit-card.svg'
import {ReactComponent as MasterCard} from '../../../../../assets/icons/masterCard.svg'
import {ReactComponent as Visa} from '../../../../../assets/icons/visa.svg'
import {ReactComponent as Wallet} from '../../../../../assets/icons/wallet.svg'
import {PAYMENT_METHOD, PaymentMethod} from '../../../../../services/interfaces/IToken'

import styles from '../PaymentMethod.module.scss'

export type PaymentMethodKey = 'wireTransfer' | 'binancePay' | 'myWallet' | 'cash'
export interface IPaymentMethod {
  className?: string
  icon: ReactNode
  iconClassName?: string
  type: PaymentMethod
  key: PaymentMethodKey
  description?: string
  descriptionIcons?: ReactNode
  link?: string
  linkDescription?: string
  linkLabel?: string
  comingSoon?: boolean
}
export const paymentMethods: IPaymentMethod[] = [
  {
    icon: <Bank />,
    iconClassName: styles.bankIcon,
    type: PAYMENT_METHOD.bank_transfer,
    key: 'cash',
    description: 'Efectivo',
  },
  {
    icon: <CreditCard />,
    iconClassName: styles.cardIcon,
    type: PAYMENT_METHOD.credit_card,
    key: 'wireTransfer',
    descriptionIcons: (
      <div className={styles.cardIcons}>
        <MasterCard />
        <Visa />
      </div>
    ),
  },
  {
    icon: <Binance />,
    iconClassName: styles.binanceIcon,
    type: PAYMENT_METHOD.binance_pay,
    key: 'binancePay',
    description: 'FREE',
    className: styles.binancePay,
    link: 'binance link',
    linkDescription: '¿Todavía no tienes una cuenta Binance Pay?',
    linkLabel: 'Créala ahora.',
    comingSoon: true,
  },
  {
    icon: <Wallet />,
    iconClassName: styles.walletIcon,
    type: PAYMENT_METHOD.wallet,
    key: 'myWallet',
    className: styles.myWallet,
  },
]
