import classNames from 'classnames'

import Card from '../Card/Card'

import styles from './OnBoardingModal.module.scss'

interface IOnBoardingModal {
  className?: string
  description?: string | null
  visible?: boolean
  activeStep: number
  totalSteps: number
  lastStep: () => void
  nextStep: () => void
}
const OnBoardingModal = ({
  className,
  description,
  visible,
  activeStep,
  totalSteps,
  lastStep,
  nextStep,
}: IOnBoardingModal) => (
  <Card
    withShadow
    className={classNames(styles.onBoarding, className, {
      [styles.visible]: visible,
    })}>
    <span>{description}</span>
    <div className={styles.footer}>
      <span>{`0${activeStep} / 0${totalSteps}`}</span>
      <div className={styles.buttons}>
        <span onClick={lastStep}>{'Atrás'}</span>
        <span onClick={nextStep}>{(totalSteps === activeStep && 'Cerrar') || 'Siguiente'}</span>
      </div>
    </div>
  </Card>
)

export default OnBoardingModal
