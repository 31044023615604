import {PATHS} from './paths'

export const headerNavLinks = [
  {
    translationKey: 'home',
    navigate: PATHS.HOME,
  },
  // {
  //   translationKey: 'invest',
  //   // url: 'https://www.facebook.com/KephiGallery/',
  // },
  // {
  //   translationKey: 'prefunding',
  //   // url: 'https://twitter.com/KephiGallery',
  // },
  // {
  //   translationKey: 'pools',
  //   // url: 'https://instagram.com/kephigallery?igshid=YmMyMTA2M2Y=',
  // },
  // {
  //   translationKey: 'academy',
  //   url: 'https://github.com/orgs/kephi-gallery/',
  // },
]

export const footerNavLinks = [
  {
    translationKey: 'explore',
    // url: 'https://www.facebook.com/KephiGallery/',
  },
  {
    translationKey: 'myInvestments',
    // url: 'https://twitter.com/KephiGallery',
  },
  {
    translationKey: 'featured',
    // url: 'https://instagram.com/kephigallery?igshid=YmMyMTA2M2Y=',
  },
  {
    translationKey: 'search',
    // url: 'https://github.com/orgs/kephi-gallery/',
  },
]
