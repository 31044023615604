import {useCallback, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as DownloadPurple} from '../../../../assets/icons/download-purple.svg'
import {ReactComponent as UserSuccess} from '../../../../assets/icons/user-success.svg'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Card/Card'
import PercentageCircle from '../../../../components/ItemCard/components/PercentageCircle/PercentageCircle'
import Modal from '../../../../components/Modal/Modal'
import {PATHS} from '../../../../constants/paths'
import useBreakpoint from '../../../../hooks/useBreakpoint'
import {BUSINESS_MODEL_TYPE, TYPOLOGY_TYPE} from '../../../../services/interfaces/IProject'
import {
  getInvestmentStorage,
  saveInvestmentStorage,
} from '../../../../store/localStorage/investment'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {useProjectSlice} from '../../../../store/slices/project'
import Characteristics from '../Characteristics/Characteristics'
import Tokenomic from '../Tokenomic/Tokenomic'

import styles from './Info.module.scss'

interface IInfoProps extends WithTranslation {
  className?: string
}

const Info = ({className, t}: IInfoProps) => {
  const navigate = useNavigate()
  const [kycModalVisible, showKycModal] = useState<boolean>(false)
  const {
    customer_info: {identity_verification_status},
  } = useCustomerSlice()
  const {
    id,
    description,
    characteristics,
    tokenomic: {investment_status},
    whitepaper_url,
    agreement_url,
    business_model,
    typology,
  } = useProjectSlice()
  const breakpoint = useBreakpoint()

  const investAction = useCallback(() => {
    if (
      !getInvestmentStorage()?.projectId ||
      (!!getInvestmentStorage()?.projectId && getInvestmentStorage()?.projectId !== id)
    )
      saveInvestmentStorage({
        projectId: id,
        activeStepNumber: undefined,
        activeSubStep: undefined,
        paymentIntentClientSecret: undefined,
        paymentMethodTypeSelected: undefined,
        isPaymentSuccessful: undefined,
      })
    identity_verification_status === 'approved'
      ? navigate(`${PATHS.INVESTMENT}/${id}`)
      : showKycModal(true)
  }, [
    id,
    identity_verification_status,
    getInvestmentStorage,
    saveInvestmentStorage,
    kycModalVisible,
  ])

  return (
    <>
      <div className={classNames(styles.container, className)}>
        <div className={styles.firstColumn}>
          <div className={styles.infoHeader}>
            <div className={styles.title}>{t(`item.typology.${TYPOLOGY_TYPE[typology]}`)}</div>
            <div className={styles.subtitle}>{t('item.outstandingFeatures')}</div>
          </div>
          <Card
            className={styles.card}
            withShadow={breakpoint !== 'm' && breakpoint !== 's' && breakpoint !== 'xs'}>
            <div className={styles.boxes}>
              {!!characteristics?.length &&
                characteristics
                  .filter(characteristic => characteristic.type === 'FEATURED')
                  .concat(
                    characteristics.filter(characteristic => characteristic.type === 'HIGHLIGHT'),
                  )
                  .map(characteristic => (
                    <div
                      key={characteristic.id}
                      className={
                        characteristic.type === 'HIGHLIGHT' ? styles.highlight : styles.box
                      }>
                      <div className={styles.value}>
                        {characteristic.value === 'true' || characteristic.value === 'false'
                          ? JSON.parse(characteristic.value)
                            ? `${t('yes')}`
                            : `${t('no')}`
                          : characteristic.value}
                      </div>
                      <span>{t(`item.characteristic.${characteristic.name}`)}</span>
                    </div>
                  ))}
              {!characteristics.length &&
                Array.from(Array(4).keys()).map(skeletonBox => (
                  <div className={classNames(styles.box, styles.skeletonBox)} key={skeletonBox}>
                    <Skeleton className={styles.square} />
                  </div>
                ))}
            </div>
            <div className={styles.line} />
            <div className={styles.description}>
              {(!!description && description) || <Skeleton width={210} />}
            </div>
          </Card>

          {breakpoint !== 'm' && breakpoint !== 's' && breakpoint !== 'xs' && <Characteristics />}
        </div>
        <div className={styles.secondColumn}>
          <div className={styles.infoHeader}>
            <div className={styles.column}>
              <div className={styles.title}>
                {t(`item.businessModel.${BUSINESS_MODEL_TYPE[business_model]}`)}
              </div>
              <div className={styles.subtitle}>{t('item.financialInformation')}</div>
            </div>
            <PercentageCircle
              percentage={investment_status}
              className={styles.percentage}
              borderColor="primary"
            />
          </div>
          <Tokenomic investAction={investAction} />
          <div className={styles.buttonRow}>
            {agreement_url && (
              <a
                className={styles.linkButton}
                href={agreement_url}
                target="_blank"
                rel="noopener noreferrer">
                <Button className={styles.button} styledType="outline" outlinedColor="secondary">
                  <DownloadPurple className={styles.icon} />
                  {t('downloadAgreement')}
                </Button>
              </a>
            )}
            {whitepaper_url && (
              <a
                className={styles.linkButton}
                href={whitepaper_url}
                target="_blank"
                rel="noopener noreferrer">
                <Button className={styles.button} styledType="outline" outlinedColor="secondary">
                  <DownloadPurple className={styles.icon} />
                  {t('downloadWhitePaper')}
                </Button>
              </a>
            )}
          </div>
          {(breakpoint === 'm' || breakpoint === 's' || breakpoint === 'xs') && <Characteristics />}
        </div>
      </div>

      <Modal
        visible={kycModalVisible}
        onClose={() => showKycModal(false)}
        containerClassName={styles.kycModal}
        icon={<UserSuccess />}
        title={'Para invertir necesitamos su KYC'}
        subtitle={'Siga los siguientes pasos en orden para continuar'}
        button={{
          label: t('continue'),
          onClick: () => navigate(`${PATHS.INVESTMENT}/${id}`),
        }}
      />
    </>
  )
}

export default withTranslation()(Info)
