import {useFormContext} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'

import {CURRENCY} from '../../../../constants/currencies'
import {formatNumber} from '../../../../utils/formatNumber'
import {FundsWithdrawalForm} from '../../FundsWithdrawal'

import styles from '../WithdrawalSteps.module.scss'

const WithdrawalConfirmation = ({t}: WithTranslation) => {
  const {getValues} = useFormContext<FundsWithdrawalForm>()

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>{t('fundsWithdrawal.confirmTransaction')}</div>
      </div>
      <div className={styles.body}>
        <div className={styles.details}>
          <div className={styles.line} />
          <div className={styles.subtitle}>{t('fundsWithdrawal.detail')}</div>
          <div className={styles.row}>
            <span>{t('fundsWithdrawal.amount')}</span>
            <span>
              {formatNumber(getValues('fundAmount'))} {CURRENCY.USDT}
            </span>
          </div>
          <div className={styles.row}>
            <span>{t('fundsWithdrawal.address')}</span>
            <span>
              {`${getValues('destinationAddress').substring(0, 6)}...${getValues(
                'destinationAddress',
              ).substring(
                getValues('destinationAddress').length - 6,
                getValues('destinationAddress').length,
              )}`}
            </span>
          </div>
          <div className={styles.row}>
            <span>{t('fundsWithdrawal.estimatedTransactionFee')}</span>
            <span>
              {0} {CURRENCY.USDT}
            </span>
          </div>
          <div className={styles.line} />
          <div className={styles.row}>
            <span className={styles.bold}>{t('fundsWithdrawal.willReceive')}</span>
            <span className={styles.bold}>
              {formatNumber(getValues('fundAmount'))} {CURRENCY.USDT}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(WithdrawalConfirmation)
