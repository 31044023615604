import {useCallback, useMemo, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ReactComponent as Time} from '../../assets/icons/time.svg'
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {useUser} from '../../contexts/UserContext'
import {useWeb3} from '../../contexts/Web3Context'
import {customerApi} from '../../services'

import withdrawalSteps from './steps/withdrawalSteps'

import styles from './FundsWithdrawal.module.scss'

export interface FundsWithdrawalForm {
  fundAmount: number
  destinationAddress: string
}

const FundsWithdrawal = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const {balance} = useUser()
  const {web3} = useWeb3()
  const [activeStep, setActiveStep] = useState<number>(0)
  const [withdrawalInProgress, showWithdrawalInProgress] = useState<boolean>(false)
  const [isWithdrawing, setWithdrawing] = useState<boolean>(false)

  const methods = useForm<FundsWithdrawalForm>({
    defaultValues: {
      destinationAddress: '',
    },
  })

  const [amount] = methods.watch(['fundAmount'])

  const confirmTransaction = useCallback(
    async (formData: FundsWithdrawalForm) => {
      setWithdrawing(true)
      const {data: responseData}: any = await customerApi.withdraw(
        formData.destinationAddress,
        formData.fundAmount,
      )

      await web3.eth
        .sendTransaction(responseData)
        .then(() => showWithdrawalInProgress(true))
        .catch((error: any) => {
          if (error?.code === -32603) return
          console.error(error)
        })
        .finally(() => setWithdrawing(false))
    },
    [web3, withdrawalInProgress, showWithdrawalInProgress, setWithdrawing, isWithdrawing],
  )

  const steps = useMemo(() => withdrawalSteps, [methods.formState.isValid])

  const nextStep = (formToSubmit: FundsWithdrawalForm) => {
    if (!methods.formState.isValid) return
    // if(activeStep === 0 && amount > +balance)
    activeStep < steps.length - 1 ? setActiveStep(activeStep + 1) : confirmTransaction(formToSubmit)
  }

  // TO DELETE
  const prevStep = () => {
    methods.clearErrors()
    activeStep > 0 && setActiveStep(activeStep - 1)
  }

  return (
    <>
      <BannerSection className={styles.section}>
        <BannerContainer className={styles.container} layout="column">
          <form className={styles.form} onSubmit={methods.handleSubmit(nextStep)}>
            <FormProvider {...methods}>{steps[activeStep]}</FormProvider>
            <Button
              type="submit"
              className={styles.button}
              styledType="filled"
              filledColor="primary"
              loading={isWithdrawing}
              disabled={activeStep === 0 && amount > +balance.replace('.', '')}>
              {activeStep < steps.length - 1 ? t('continue') : t('confirm')}
            </Button>
            {activeStep !== 0 && (
              <Button
                className={styles.button}
                styledType="outline"
                // filledColor="primary"
                onClick={() => {
                  prevStep()
                }}>
                Prev
              </Button>
            )}
          </form>
        </BannerContainer>
      </BannerSection>

      <Modal
        visible={withdrawalInProgress}
        onClose={() => showWithdrawalInProgress(false)}
        closeButtonHidden
        outerClassName={styles.fundsModal}
        icon={
          <div className={styles.pending}>
            <Time />
            <span>{t('pending')}</span>
          </div>
        }
        title={t('fundsWithdrawal.processingWithdrawal')}
        subtitle={t('fundsWithdrawal.transactionTimeWarning')}
        button={{
          label: t('goToProfile'),
          onClick: () => navigate(PATHS.PORTFOLIO),
        }}
      />
    </>
  )
}

export default withTranslation()(FundsWithdrawal)
