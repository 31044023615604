import {CURRENCY} from '../../constants/currencies'
import axios from '../../core/axios'

export interface IBankDetail {
  currency: keyof typeof CURRENCY
  account_number: string
}

export default {
  buyWithWeb3: (quantity: number, token_id: number) =>
    axios.post('sales/w3buy/', {
      quantity,
      token_id,
    }),
  createPaymentLink: (quantity: number, token_id: number) =>
    axios.post('sales/create_payment_link/', {
      quantity,
      token_id,
    }),
  requestApprove: (quantity: number, token_id: number) =>
    axios.post('sales/approve/', {
      quantity,
      token_id,
    }),
  onBuySuccess: (
    block_number: number,
    transaction_hash: string,
    token_id: number,
    quantity?: number,
  ) =>
    axios.post('sales/web3/success/', {
      block_number,
      token_id,
      transaction_hash,
      quantity,
    }),
  stripeHook: () => axios.post('sales/stripeHook/'),
  reserve: (
    quantity: number,
    token_id: number,
    currency: keyof typeof CURRENCY | 'CASH',
    file: string,
  ) =>
    axios.post('sales/reserve/', {
      quantity,
      token_id,
      currency,
      file,
    }),
  getBankDetails: async (tokenId: number): Promise<IBankDetail[]> => {
    const {data} = await axios.get(`sales/bank_details/${tokenId}/`)
    return data as IBankDetail[]
  },
  allowance: async (quantity: number, token_id: number): Promise<boolean> => {
    const {
      data: {allowed},
    } = await axios.post('sales/allowance/', {quantity, token_id})
    return allowed
  },
  uploadFile: async (file: FormData): Promise<string> => {
    const {
      data: {file_route},
    } = await axios.post('sales/upload/', file)
    return file_route
  },
}
