import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Download} from '../../../../assets/icons/download.svg'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Card/Card'
import {useProjectSlice} from '../../../../store/slices/project'

import styles from './Characteristics.module.scss'

interface ICharacteristicsProps extends WithTranslation {
  className?: string
}
const Characteristics = ({className, t}: ICharacteristicsProps) => {
  const {characteristics, brochure_url} = useProjectSlice()

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.cardsContainer}>
        <Card className={styles.body} withShadow>
          <div className={styles.title}>{t('item.general')}</div>
          <div className={styles.characteristicsRow}>
            {!!characteristics?.length &&
              characteristics
                .filter(characteristic => characteristic.type === 'GENERAL')
                .map(characteristic => (
                  <div key={characteristic.id} className={styles.characteristic}>
                    {t(`item.characteristic.${characteristic.name}`)}{' '}
                    <span>
                      {characteristic.value === 'true' || characteristic.value === 'false'
                        ? JSON.parse(characteristic.value)
                          ? `${t('yes')}`
                          : `${t('no')}`
                        : characteristic.value}
                    </span>
                  </div>
                ))}
          </div>
        </Card>
        <Card className={styles.body} withShadow>
          <div className={styles.title}>{t('item.unitFeatures')}</div>
          <div className={styles.characteristicsRow}>
            {!!characteristics?.length &&
              characteristics
                .filter(characteristic => characteristic.type === 'AMENITIES')
                .map(characteristic => (
                  <div key={characteristic.id} className={styles.characteristic}>
                    {t(`item.characteristic.${characteristic.name}`)}{' '}
                    <span>
                      {characteristic.value === 'true' || characteristic.value === 'false'
                        ? JSON.parse(characteristic.value)
                          ? `${t('yes')}`
                          : `${t('no')}`
                        : characteristic.value}
                    </span>
                  </div>
                ))}
          </div>
        </Card>
      </div>
      {brochure_url && (
        <div className={styles.buttonRow}>
          <a
            className={styles.linkButton}
            href={brochure_url}
            target="_blank"
            rel="noopener noreferrer">
            <Button className={styles.button} filledColor="secondary">
              <Download />
              {t('downloadBrochure')}
            </Button>
          </a>
        </div>
      )}
    </div>
  )
}

export default withTranslation()(Characteristics)
