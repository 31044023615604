import {ButtonHTMLAttributes, PropsWithChildren} from 'react'
import classNames from 'classnames'

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

import styles from './Button.module.scss'

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  onClick?: () => void
  tabIndex?: number
  onKeyDown?: () => void
  ariaLabel?: string
  filledColor?: 'primary' | 'secondary' | 'light' | 'white' | 'trasparent' | 'primary-gradient'
  outlinedColor?: 'primary' | 'secondary' | 'light' | 'white' | 'dark'
  onMouseLeave?: () => void
  disabled?: boolean
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'
  shape?: 'rectangle' | 'circle' | 'square'
  shapeSize?: number
  styledType?: 'outline' | 'filled'
}

const Button = ({
  className,
  children,
  type = 'button',
  disabled,
  loading,
  filledColor = 'white',
  outlinedColor,
  shape,
  shapeSize,
  styledType,
  ...restProps
}: PropsWithChildren<IButton>) => {
  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={classNames(
        styles.button,
        className,
        `${styledType ? styles[`button-${styledType}`] : ''}`,
        styles[`background-${filledColor}`],
        styles[`border-${outlinedColor}`],
        styles[`${shape}`],
        {
          [styles.loading]: loading,
          [styles.disabled]: disabled,
        },
      )}
      style={{
        height: `${shapeSize ? `${shapeSize}rem` : ''}`,
        width: `${shapeSize ? `${shapeSize}rem` : ''}`,
        minWidth: `${shapeSize ? `${shapeSize}rem` : ''}`,
        minHeight: `${shapeSize ? `${shapeSize}rem` : ''}`,
      }}
      {...restProps}>
      {children}
      {!!loading && <LoadingSpinner />}
    </button>
  )
}

export default Button
