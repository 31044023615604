export const NAME = 'name'
export const SURNAME = 'surname'
export const BIRTHDATE = 'birthdate'
export const MOBILE = 'mobile'
export const STREET = 'street'
export const NUMBER = 'number'
export const POSTAL_CODE = 'postalCode'
export const CITY = 'city'
export const COUNTRY = 'country'
export const MAIL = 'mail'
export const POLITICALLY_EXPOSED_CHECKBOX = 'notPoliticallyExposed'
export const US_RESIDENT_CHECKBOX = 'notUsResident'

export interface IPersonalInformationBaseForm {
  name: string
  surname: string
  mail: string
  notPoliticallyExposed: boolean
  notUsResident: boolean
}
export interface IPersonalInformationForm extends IPersonalInformationBaseForm {
  birthdate: string
  mobile: string | number
  street: string
  number: string | number
  postalCode: string
  city: string
  country: string
}
