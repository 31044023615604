import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'
import {FastAverageColor} from 'fast-average-color'

import {ReactComponent as ArrowDown} from '../../../../assets/icons/arrow-down.svg'
import {ReactComponent as ArrowUp} from '../../../../assets/icons/arrow-up.svg'
import Map from '../../../../components/Map/Map'
import Slider from '../../../../components/Slider/Slider'
import {GALLERY_KEY, navigatorButtons} from '../../../../constants/navigatorButtons'
import {itemImagesSettings} from '../../../../constants/sliderSettings'
import useBreakpoint from '../../../../hooks/useBreakpoint'

import styles from './Navigator.module.scss'
interface INavigatorProps extends WithTranslation {
  className?: string
  assets: string[]
  center: {lat: number; lng: number}
}

const Navigator = ({className, assets, center, t}: INavigatorProps) => {
  const [activeButtonKey, setActiveButtonKey] = useState<string>(GALLERY_KEY)
  const [mainAsset, setMainAsset] = useState<string>()
  const [averageColor, setAverageColor] = useState<string>('black')
  const breakpoint = useBreakpoint()
  const fac = new FastAverageColor()

  const renderImage = (image: string, slideClass: string) => (
    <>
      {!!assets?.length ? (
        <img className={slideClass} src={image} onClick={() => setMainAsset(image)} />
      ) : (
        <Skeleton className={slideClass} height="100%" width="100%" />
      )}
    </>
  )

  useEffect(() => {
    itemImagesSettings.direction =
      breakpoint !== 's' && breakpoint !== 'xs' ? 'vertical' : 'horizontal'
  }, [breakpoint])

  useEffect(() => {
    if (!assets?.length) return
    if (!mainAsset) {
      setMainAsset(assets[0])
      return
    }
    fac
      .getColorAsync(mainAsset)
      .then(color => {
        setAverageColor(color.rgb)
      })
      .catch(e => {
        console.log(e)
      })
  }, [assets, mainAsset])

  const onNextSlide = () => (document?.querySelector('.swiper') as any)?.swiper?.slideNext()
  const onPrevSlide = () => (document?.querySelector('.swiper') as any)?.swiper?.slidePrev()

  const NavigatorBtns = useCallback(() => {
    return (
      <div
        className={classNames(styles.navigateButtonsRow, {
          [styles.forceLeft]: activeButtonKey !== GALLERY_KEY,
        })}>
        {navigatorButtons.map(navigatorButton => (
          <div
            key={navigatorButton.key}
            className={classNames(styles.navigateButton, {
              [styles.active]: navigatorButton.key === activeButtonKey,
              [styles.disabled]: navigatorButton.disabled,
            })}
            onClick={() => setActiveButtonKey(navigatorButton.key)}>
            {navigatorButton.icon}
            {(navigatorButton.labelKey && t(navigatorButton.labelKey)) || navigatorButton?.label}
          </div>
        ))}
      </div>
    )
  }, [navigatorButtons, activeButtonKey])

  return (
    <div className={styles.mainContainer}>
      <div className={classNames(styles.navigator, className)}>
        {(activeButtonKey === GALLERY_KEY && (
          <div className={classNames(styles.main, styles.desktopImage)}>
            {!!assets?.length && !!mainAsset ? (
              <div
                className={styles.itemImage}
                style={{backgroundImage: `url(${mainAsset})`, backgroundColor: averageColor}}
              />
            ) : (
              <div className={styles.itemImage}>
                <Skeleton height="100%" width="100%" />
              </div>
            )}
          </div>
        )) || (
          <div className={styles.main}>
            <Map center={center} />
          </div>
        )}
        <div
          className={classNames(styles.secondary, {
            [styles.visible]: activeButtonKey === GALLERY_KEY,
          })}>
          <div className={styles.prevArrow}>
            <ArrowUp className={styles.box} onClick={onPrevSlide} />
          </div>
          <Slider
            slides={!!assets.length ? assets : Array.from(Array(4).keys())}
            className={styles.slider}
            customClasses={{
              swiperSlide: styles.swiperSlide,
              slide: styles.slide,
            }}
            renderSlide={renderImage}
            settings={itemImagesSettings}
          />
          <div className={styles.nextArrow}>
            <ArrowDown className={styles.box} onClick={onNextSlide} />
          </div>
        </div>
      </div>
      <NavigatorBtns />
    </div>
  )

  // return (
  //   <div className={classNames(styles.navigator, className)}>
  //     {breakpoint !== 's' && breakpoint !== 'xs' ? (
  //       <div className={styles.main}>
  //         {(activeButtonKey === GALLERY_KEY && (
  //           <div
  //             className={styles.itemImage}
  //             style={{backgroundImage: `url(${mainAsset})`, backgroundColor: averageColor}}></div>
  //         )) || <Map center={center} />}

  //         <NavigatorBtns />
  //       </div>
  //     ) : (
  //       <></>
  //     )}
  //     <div
  //       className={classNames(styles.secondary, {
  //         [styles.visible]: activeButtonKey === GALLERY_KEY,
  //       })}>
  //       <div className={styles.prevArrow}>
  //         <ArrowUp className={styles.box} onClick={onPrevSlide} />
  //       </div>
  //       <Slider
  //         slides={assets}
  //         className={styles.slider}
  //         customClasses={{
  //           swiperSlide: styles.swiperSlide,
  //           slide: styles.slide,
  //         }}
  //         renderSlide={renderImage}
  //         settings={itemImagesSettings}
  //       />
  //       <div className={styles.nextArrow}>
  //         <ArrowDown className={styles.box} onClick={onNextSlide} />
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default withTranslation()(Navigator)
