import {ReactNode, useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as ArrowDown} from '../../../../assets/icons/arrow-down.svg'
import {ReactComponent as Deposit} from '../../../../assets/icons/deposit.svg'
import {ReactComponent as Plus} from '../../../../assets/icons/plus.svg'
import {ReactComponent as Withdrawal} from '../../../../assets/icons/withdrawal.svg'
import Card from '../../../../components/Card/Card'
import useBreakpoint from '../../../../hooks/useBreakpoint'
import {activityApi} from '../../../../services'
import {ACTION_TYPE, ActionType, IActivity, IActivityData} from '../../../../services/api/activity'
import {formatAddress} from '../../../../utils/formatAddress'

import styles from './RecentTransactionsMaximized.module.scss'

interface IActionTypeKey {
  icon: ReactNode
  className: string
  sign: '-' | '+'
}
const ACTION_TYPE_ICON: {[key in ActionType]: IActionTypeKey} = {
  buy: {icon: <Plus />, className: styles.blue, sign: '-'},
  sell: {icon: <Plus />, className: styles.green, sign: '+'},
  fund: {icon: <Deposit />, className: styles.green, sign: '+'},
  withdraw: {icon: <Withdrawal />, className: styles.red, sign: '-'},
  reserve: {icon: <Plus />, className: styles.green, sign: '+'},
}

enum HEADER_TABLE {
  type = 'type',
  project = 'project',
  date = 'date',
  wallet = 'wallet',
  amount = 'amount',
}
const headers: string[] = Object.values(HEADER_TABLE)

type ActionFilterType = ActionType | 'all'
const actionTypeFilters: ActionFilterType[] = ['all'].concat(
  Object.values<ActionType>(ACTION_TYPE) as ActionType[],
) as ActionType[]

const RecentTransactionsMaximized = ({t}: WithTranslation) => {
  const navigate = useNavigate()
  const breakpoint = useBreakpoint()
  const [activityData, setActivityData] = useState<IActivityData>()
  const [isAscendingOrder, changeOrder] = useState<boolean>(false)
  const [filterSelected, setFilterSelected] = useState<ActionFilterType>('all')
  const [isFetching, setFetching] = useState<boolean>(false)

  const getAllCustomerActivity = useCallback(async () => {
    try {
      setFetching(true)
      const activityDataResponse: IActivityData = await activityApi.getAllActivity({
        ordering: isAscendingOrder ? '+created_at' : '-created_at',
        ...(filterSelected !== 'all' && {action: filterSelected}),
      })
      setActivityData(activityDataResponse)
    } catch (error) {
    } finally {
      setFetching(false)
    }
  }, [isAscendingOrder, activityData, isFetching, filterSelected])

  const fetchNextPage = useCallback(
    async (nextPage: number) => {
      try {
        setFetching(true)
        const activityDataNext: IActivityData = await activityApi.getAllActivity({
          ordering: isAscendingOrder ? '+created_at' : '-created_at',
          ...(filterSelected !== 'all' && {action: filterSelected}),
          page: nextPage,
        })
        const activityDataUpdated = {
          ...activityDataNext,
          results: [...(activityData?.results as IActivity[]), ...activityDataNext.results],
        }
        setActivityData(activityDataUpdated)
      } catch (error) {
      } finally {
        setFetching(false)
      }
    },
    [isAscendingOrder, activityData, isFetching, filterSelected],
  )

  const handleChangeOrdering = useCallback(() => {
    changeOrder(!isAscendingOrder)
  }, [isAscendingOrder, activityData])

  useEffect(() => {
    if (!!activityData) setActivityData(undefined)
    getAllCustomerActivity()
  }, [isAscendingOrder, filterSelected])

  return (
    <div className={styles.recentTransactions}>
      <div className={styles.header}>
        <div className={styles.row}>
          <div className={styles.titleBox} onClick={() => navigate(-1)}>
            <ArrowDown />
            {t('profile.recentTransactions')}
          </div>
          <div className={classNames(styles.orderButton, {[styles.ascending]: isAscendingOrder})}>
            {t('order')}
            <ArrowDown onClick={() => handleChangeOrdering()} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.filters}>
            {actionTypeFilters.map(actionTypeFilter => (
              <div
                key={actionTypeFilter}
                className={classNames(styles.filter, {
                  [styles.selected]: filterSelected === actionTypeFilter,
                })}
                onClick={() => setFilterSelected(actionTypeFilter)}>
                {t(`actionTypeFilter.${actionTypeFilter}`)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.cardContainer}>
        <Card className={styles.card} withShadow>
          <table className={styles.table}>
            <tbody className={styles.body}>
              <tr className={styles.headers}>
                {headers.map(header => (
                  <th
                    key={header}
                    className={classNames(styles.columnHeader, {
                      [styles.grow]: header === HEADER_TABLE.wallet,
                      [styles.shrink]: header === HEADER_TABLE.amount,
                    })}>
                    {t(`recentTransactions.header.${header}`)}
                  </th>
                ))}
              </tr>
              {!!activityData?.results?.length &&
                activityData.results.map((recentTransaction: IActivity) => (
                  <tr className={styles.transaction} key={recentTransaction.id}>
                    <td className={styles.column}>
                      <div className={styles.type}>
                        <div className={styles.icon}>
                          {ACTION_TYPE_ICON[recentTransaction.action].icon}
                        </div>
                        {t(`activityAction.${recentTransaction.action}`)}
                      </div>
                    </td>
                    <td className={styles.column}>
                      {((recentTransaction.action === 'withdraw' ||
                        recentTransaction.action === 'fund') &&
                        '-') ||
                        recentTransaction.project_name ||
                        '-'}
                    </td>
                    <td className={styles.column}>
                      {new Date(recentTransaction.created_at).toLocaleDateString()}
                    </td>
                    <td className={classNames(styles.column, {[styles.grow]: true})}>
                      {(!!recentTransaction.wallet &&
                        breakpoint &&
                        formatAddress(recentTransaction.wallet, breakpoint)) ||
                        '-'}
                    </td>
                    <td
                      className={classNames(
                        styles.column,
                        styles.amount,
                        ACTION_TYPE_ICON[recentTransaction.action].className,
                        {[styles.shrink]: true},
                      )}>{`${
                      ACTION_TYPE_ICON[recentTransaction.action].sign
                    } ${+recentTransaction.amount} ${
                      (recentTransaction.currency.toLocaleUpperCase() === 'CASH' &&
                        t('investment.reserve.cash')) ||
                      recentTransaction.currency
                    }`}</td>
                  </tr>
                ))}
              {isFetching &&
                Array.from(Array(20).keys()).map(skeletonRow => (
                  <tr className={styles.transaction} key={skeletonRow}>
                    <td className={styles.column}>
                      <Skeleton width="80%" />
                    </td>
                    <td className={styles.column}>
                      <Skeleton width="80%" />
                    </td>
                    <td className={styles.column}>
                      <Skeleton width="80%" />
                    </td>
                    <td className={classNames(styles.column, {[styles.grow]: true})}>
                      <Skeleton width="80%" />
                    </td>
                    <td
                      className={classNames(styles.column, styles.amount, {[styles.shrink]: true})}>
                      <Skeleton width="80%" />
                    </td>
                  </tr>
                ))}
              {!isFetching && !activityData?.results?.length && (
                <tr className={styles.emptyText}>
                  <td>{t('profile.noRecentTransaction')}</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              {!!activityData?.next && (
                <tr className={styles.seeMore}>
                  <td onClick={() => fetchNextPage(activityData.next)}>
                    <span>{t('seeMore')}</span> <ArrowDown />
                  </td>
                </tr>
              )}
            </tfoot>
          </table>
        </Card>
      </div>
    </div>
  )
}
export default withTranslation()(RecentTransactionsMaximized)
