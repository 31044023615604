import {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import Card from '../../../../components/Card/Card'
import OnBoardingModal from '../../../../components/OnBoardingModal/OnBoardingModal'
import {currentCurrency} from '../../../../constants/currencies'
import {customerApi} from '../../../../services'
import {IInvested} from '../../../../services/api/customer'
import {ONBOARDING_TYPE, useOnBoarding} from '../../../../store/slices/onBoarding'

import styles from './TotalInvested.module.scss'

const TotalInvested = ({t}: WithTranslation) => {
  const [invested, setInvested] = useState<IInvested>()
  const [fetching, setFetching] = useState<boolean>(false)
  const {activeStep, totalSteps, lastStep, nextStep, onBoardingCompleted} = useOnBoarding()

  const getCustomerInvested = useCallback(async () => {
    try {
      setFetching(true)
      const investedData = await customerApi.getInvested()
      setInvested(investedData)
    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }, [])

  useEffect(() => {
    getCustomerInvested()
  }, [])

  return (
    <Card
      className={classNames(
        styles.totalInvested,
        (activeStep.name === ONBOARDING_TYPE.INVESTMENTS && !onBoardingCompleted && 'dim') || '',
      )}
      withShadow>
      <div className={styles.box}>
        <div className={styles.header}>
          <span>{t('profile.myInvestments')}</span>
        </div>
        <div className={styles.row}>
          <div className={styles.history}>
            <div className={styles.column}>
              <div className={styles.text}>{t('profile.purchasedTokens')}</div>
              <div className={classNames(styles.value, {[styles.loading]: fetching})}>
                {!fetching ? invested?.tokens || 0 : <Skeleton width={60} height={40} />}
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.text}>{t('profile.totalInvestment')}</div>
              <div className={classNames(styles.value, {[styles.loading]: fetching})}>
                {!fetching ? (
                  <>
                    {invested?.amount || 0}
                    <span>{invested?.currency || currentCurrency}</span>
                  </>
                ) : (
                  <Skeleton width={60} height={40} />
                )}
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.text}>{t('profile.projectsAmount')}</div>
              <div className={classNames(styles.value, {[styles.loading]: fetching})}>
                {!fetching ? invested?.projects || 0 : <Skeleton width={60} height={40} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <OnBoardingModal
        className={styles.onBoarding}
        description={'Aquí encontrarás toda la información correspondiente a tu Billetera'}
        visible={activeStep.name === ONBOARDING_TYPE.INVESTMENTS && !onBoardingCompleted}
        activeStep={activeStep.number}
        totalSteps={totalSteps}
        lastStep={lastStep}
        nextStep={nextStep}
      />
    </Card>
  )
}
export default withTranslation()(TotalInvested)
