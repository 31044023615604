import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as EstimatedReturnIcon} from '../../assets/icons/estimated-return.svg'
import {ReactComponent as TokenPriceIcon} from '../../assets/icons/token-price.svg'
import {CURRENCY_SYMBOL} from '../../constants/currencies'
import {PATHS} from '../../constants/paths'
import {useUser} from '../../contexts/UserContext'
import {useWallet} from '../../contexts/WalletContext'
import {IProject, STATUS_TYPE} from '../../services/interfaces/IProject'
import {useProjectSlice} from '../../store/slices/project'
import {formatNumber} from '../../utils/formatNumber'
import Button from '../Button/Button'

import ItemProperty from './components/ItemProperty/ItemProperty'
import PercentageCircle from './components/PercentageCircle/PercentageCircle'

import styles from './ItemCard.module.scss'

interface IItemCardProps {
  className?: string
  layout?: 'row' | 'column'
  showSoldTokens?: boolean
  showItemStatus?: boolean
  showPercentageFloating?: boolean
  itemData: IProject
  mainColor?: 'primary' | 'secondary' | 'light' | 'white' | 'trasparent' | 'primary-gradient'
  design?: 'first' | 'second'
  straightCorners?: boolean
}

const ItemCard = ({
  className,
  layout = 'row',
  showSoldTokens,
  showItemStatus,
  showPercentageFloating,
  itemData,
  mainColor = 'primary',
  design = 'first',
  straightCorners,
}: IItemCardProps) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {id: projectId, clearProject} = useProjectSlice()
  const {user} = useUser()
  const {isLogging, connectWallet} = useWallet()
  const {
    id,
    name,
    address: {city, country},
    tokenomic: {
      total_objective,
      investment_status,
      return_over_investment,
      token: {price, currency},
    },
    assets,
  } = itemData

  const seeMoreAction = useCallback(() => {
    if (!user) {
      connectWallet(() => navigate(`${PATHS.ITEM}/${id || 1}`))
      return
    }
    if (projectId !== id) clearProject()
    navigate(`${PATHS.ITEM}/${id || 1}`)
  }, [user])

  return (
    <div
      className={classNames(styles.card, className, styles[layout], styles[design], {
        [styles.showSoldTokens]: showSoldTokens && investment_status > 0 && investment_status < 100,
        [styles.showItemStatus]: showItemStatus,
        [styles.straightCorners]: straightCorners,
      })}>
      <div className={classNames(styles.status, styles[STATUS_TYPE[itemData.status]])}>
        {t(`item.status.${STATUS_TYPE[itemData.status]}`)}
      </div>
      <div
        className={classNames(styles.percentageFloating, styles[design], {
          [styles.show]: showPercentageFloating,
        })}>
        <PercentageCircle
          percentage={investment_status}
          className={styles.percentage}
          borderColor="primary"
        />
      </div>
      <div className={styles.info}>
        <div className={styles.headerInfo}>
          <span className={styles.name}>{name}</span>
          <span
            className={classNames(
              styles.location,
              styles[mainColor],
            )}>{`${city} - ${country}`}</span>
        </div>
        <div className={styles.propertiesContainer}>
          <ItemProperty
            icon="$"
            label={`${t('item.target')}`}
            property={
              itemData.status === 'UNDER_REVIEW' ? (
                <span>-</span>
              ) : (
                <>
                  <span>{formatNumber(+total_objective)}</span> {CURRENCY_SYMBOL[currency]}
                </>
              )
            }
          />
          <ItemProperty
            icon={<TokenPriceIcon />}
            label={`${t('item.tokenPrice')}`}
            property={
              itemData.status === 'UNDER_REVIEW' ? (
                <span>-</span>
              ) : (
                <>
                  <span>{formatNumber(+price)}</span> {CURRENCY_SYMBOL[currency]}
                </>
              )
            }
          />
          <ItemProperty
            icon={<EstimatedReturnIcon />}
            label={`${t('item.estimatedAnnualReturn')}`}
            property={
              itemData.status === 'UNDER_REVIEW' ? (
                <span className={styles.percentage}>-</span>
              ) : (
                <>
                  <span className={styles.percentage}>
                    {formatNumber(+return_over_investment)}%
                  </span>
                </>
              )
            }
          />
        </div>
        <Button
          disabled={itemData.status === 'SOLD' || itemData.status === 'UNDER_REVIEW'}
          className={classNames(styles.button, styles[STATUS_TYPE[itemData.status]])}
          filledColor="primary"
          loading={isLogging}
          onClick={() => seeMoreAction()}>
          {itemData.status === 'UNDER_REVIEW' ? t('soon') : t('seeMore')}
        </Button>
      </div>
      <div className={styles.soldTokens}>
        <span className={styles.label}>{t('item.soldTokens')}</span>
        {/* <PercentageCircle percentage={+investment_status} className={styles.percentage} /> */}
      </div>
      <div
        className={classNames(styles.itemImage, styles[design])}
        style={{backgroundImage: `url(${assets[0]})`}}></div>
    </div>
  )
}

export default ItemCard
