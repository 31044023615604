import axios from 'axios'
import {create, StateCreator} from 'zustand'

import {customerApi} from '../../services'
import {ICustomerData} from '../../services/api/customer'
import {ICustomerInfo} from '../../services/interfaces/ICustomerInfo'
import {IOwnershipInfo} from '../../services/interfaces/IOwnershipInfo'

interface CustomerDataState extends ICustomerData {
  customer_info: ICustomerInfo
  customerTokens: IOwnershipInfo[] | []
  token?: string | null
  walletAddress?: string | null
  logs?: any[]
  isFetching?: boolean
  hasEssentialData: boolean
}

type CustomerActions = {
  setToken: (token: string) => void
  setWalletAddress: (address: string) => void
  setCustomerData: (info: ICustomerData) => void
  setCustomerTokens: (data: IOwnershipInfo[]) => void
  setEssentialData: (data: ICustomerInfo) => void
  reset: () => void
  setLogs: (log: string | any) => void
  setFetching: (value: boolean) => void
  refreshCustomer: () => void
}

interface CustomerSlice extends CustomerDataState, CustomerActions {}

const initialState: CustomerDataState = {
  customer_info: {
    id: 0,
    first_name: '',
    last_name: '',
    birth_date: '',
    email: '',
    address: {
      street: '',
      city: '',
      state: '',
      cp: '',
      country: '',
      number: '',
      province: '',
      one_line: '',
      latitude: '',
      longitude: '',
    },
  },
  customerTokens: [],
  token: localStorage.getItem('token'),
  logs: [],
  isFetching: true,
  hasEssentialData: false,
}

const hasCustomerEssentialData = (data: ICustomerInfo): boolean =>
  !!data.first_name && !!data.last_name && !!data.email && !!data.birth_date

const createCustomerSlice: StateCreator<CustomerSlice> = set => ({
  ...initialState,

  setToken: (token: string) => {
    set({token: token})
    localStorage.setItem('token', token)
    axios.defaults.headers.common['Authorization'] = `${
      !!localStorage.getItem('token') ? `Token ${localStorage.getItem('token')}` : ''
    }`
  },

  setWalletAddress: (address: string) => {
    set({walletAddress: address})
  },

  setEssentialData: (data: ICustomerInfo) => {
    set(state => ({
      customer_info: {
        ...state.customer_info,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        birth_date: data.birth_date,
      },
      hasEssentialData: hasCustomerEssentialData(data),
    }))
    set({isFetching: false})
  },
  setCustomerData: (data: ICustomerData) => {
    set({
      ...data,
      hasEssentialData: hasCustomerEssentialData(data?.customer_info),
    })
    set({isFetching: false})
  },
  setCustomerTokens: (data: IOwnershipInfo[]) => {
    set({customerTokens: data})
    // set({isFetching: false})
  },

  reset: () => {
    set(initialState)
    localStorage.clear()
  },

  setLogs: (log: any) => {
    set(state => ({logs: state.logs?.concat(log)}))
  },

  setFetching: (value: boolean) => {
    set({isFetching: value})
  },

  refreshCustomer: async () => {
    const customerData = await customerApi.getCustomerData()
    set({
      ...customerData,
      hasEssentialData: hasCustomerEssentialData(customerData?.customer_info),
    })
  },
})

export const useCustomerSlice = create<CustomerSlice>()((...a) => ({
  ...createCustomerSlice(...a),
}))
