import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as CalendarTime} from '../../../../assets/icons/calendar-time.svg'
import {ReactComponent as Coins} from '../../../../assets/icons/coins.svg'
import {ReactComponent as ColumnChart} from '../../../../assets/icons/column-chart.svg'
import {ReactComponent as EstimatedReturnIcon} from '../../../../assets/icons/estimated-return.svg'
import {ReactComponent as PieChart} from '../../../../assets/icons/pie-chart-white.svg'
import {ReactComponent as Receive} from '../../../../assets/icons/receive.svg'
import {ReactComponent as Redirect} from '../../../../assets/icons/redirect.svg'
import {ReactComponent as Star} from '../../../../assets/icons/star.svg'
import {ReactComponent as TokenPriceIcon} from '../../../../assets/icons/token-price.svg'
import Button from '../../../../components/Button/Button'
import Card from '../../../../components/Card/Card'
import ItemProperty from '../../../../components/ItemCard/components/ItemProperty/ItemProperty'
import {CURRENCY_SYMBOL} from '../../../../constants/currencies'
import {BUSINESS_MODEL_TYPE} from '../../../../services/interfaces/IProject'
import {useProjectSlice} from '../../../../store/slices/project'
import {formatNumber} from '../../../../utils/formatNumber'
import TokenCounter from '../TokenCounter/TokenCounter'

import styles from './Tokenomic.module.scss'

interface ITokenomicProps extends WithTranslation {
  investAction: () => void
}

const Tokenomic = ({t, investAction}: ITokenomicProps) => {
  const {
    business_model,
    tokenomic: {
      total_objective,
      minimum_investment,
      return_over_investment,
      estimated_return,
      dividend_period,
      rental_return_over_investment,
      capital_gain_after_sale,
      rent_start_date,
      token,
    },
  } = useProjectSlice()

  return (
    <Card className={styles.card} withShadow>
      <div className={styles.investmentBox}>
        <ItemProperty
          icon="$"
          label={`${t('item.totalFund')}`}
          property={
            <>
              <span>{formatNumber(+total_objective || 0)}</span> {CURRENCY_SYMBOL[token?.currency]}
            </>
          }
          oneLine
        />
        <ItemProperty
          icon={<TokenPriceIcon />}
          label={`${t('item.tokenPrice')}`}
          property={
            <>
              <span>{formatNumber(+token?.price || 0)}</span> {CURRENCY_SYMBOL[token?.currency]}
            </>
          }
          oneLine
        />
        <ItemProperty
          icon={<ColumnChart />}
          label={`${t('item.minimumInvestment')}`}
          property={
            <span className={styles.percentage}>{`${+minimum_investment || 0} token${
              (+minimum_investment > 1 && 's') || ''
            }`}</span>
          }
          oneLine
        />
        <ItemProperty
          icon={<EstimatedReturnIcon />}
          label={`${t('item.estimatedAnnualReturn')}`}
          property={
            <span className={styles.percentage}>{formatNumber(+return_over_investment || 0)}%</span>
          }
          oneLine
        />
        <ItemProperty
          icon={<CalendarTime />}
          label={`${t('item.estimatedPeriod')}`}
          property={<span className={styles.label}>{dividend_period || ''}</span>}
          oneLine
        />
        {business_model !== BUSINESS_MODEL_TYPE.RENT_CAPITAL_GAIN.toLocaleUpperCase() && (
          <ItemProperty
            icon={<PieChart />}
            label={`${t('item.projectRateOfReturn')}`}
            property={
              <span className={styles.percentage}>{formatNumber(+estimated_return || 0)}%</span>
            }
            oneLine
          />
        )}
        {business_model === BUSINESS_MODEL_TYPE.RENT_CAPITAL_GAIN.toLocaleUpperCase() && (
          <>
            <ItemProperty
              icon={<Coins />}
              label={`${t('item.rentalReturnOverInvestment')}`}
              property={
                <span className={styles.percentage}>
                  {formatNumber(+rental_return_over_investment || 0)}%
                </span>
              }
              oneLine
            />
            <ItemProperty
              icon={<Receive />}
              label={`${t('item.capitalGainAfterSale')}`}
              property={
                <span className={styles.percentage}>
                  {formatNumber(+capital_gain_after_sale || 0)}%
                </span>
              }
              oneLine
            />
            <ItemProperty
              icon={<CalendarTime />}
              label={`${t('item.rentStartDate')}`}
              property={
                <span className={styles.label}>
                  {new Date(rent_start_date).toLocaleDateString()}
                </span>
              }
              oneLine
            />
          </>
        )}
        <ItemProperty
          icon={<Star />}
          label={`${t('item.tokenDetail')}`}
          property={
            <>
              <span className={styles.redirect}>
                {token?.name}
                <a
                  className={styles.buttonRedirect}
                  href={`https://mumbai.polygonscan.com/token/${token?.nft_contract_address}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Redirect />
                </a>
              </span>
            </>
          }
          oneLine
        />
        <div className={styles.line} />
        <div className={styles.footer}>
          <TokenCounter />

          <Button className={styles.button} filledColor="primary" onClick={() => investAction()}>
            {t('invest')}
          </Button>
        </div>
      </div>
    </Card>
  )
}

export default withTranslation()(Tokenomic)
