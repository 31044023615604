import {useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Arrow} from '../../../../assets/icons/arrow-up.svg'
import InfoPanel from '../../../../components/InfoPanel/InfoPanel'
import {PAYMENT_METHOD} from '../../../../services/interfaces/IToken'
import {saveInvestmentStorage} from '../../../../store/localStorage/investment'
import {useCustomerSlice} from '../../../../store/slices/customer'
import {usePaymentMethodSlice} from '../../../../store/slices/paymentMethod'
import {useProjectSlice} from '../../../../store/slices/project'
import {useStepperSlice} from '../../../../store/slices/stepper'

import {IPaymentMethod, paymentMethods} from './constant/methods'

import styles from './PaymentMethod.module.scss'

const PaymentMethod = ({t}: WithTranslation) => {
  const {setActivePaymentMethod} = usePaymentMethodSlice()
  const {nextStep} = useStepperSlice()
  const {
    customer_info: {identity_verification_status},
    refreshCustomer,
  } = useCustomerSlice()
  const {
    id,
    tokenomic: {token},
  } = useProjectSlice()
  const [paymentMethodsFiltered, setPaymentMethodsFiltered] = useState<IPaymentMethod[]>()

  const isKycPending = identity_verification_status && identity_verification_status !== 'approved'

  useEffect(() => {
    if (!isKycPending) return
    setTimeout(() => refreshCustomer(), 1000)
    const interval = setInterval(refreshCustomer, 30000)
    return () => clearInterval(interval)
  }, [refreshCustomer])

  useEffect(() => {
    if (!!id && !!token) {
      setPaymentMethodsFiltered(
        (!!token?.payment_methods?.length &&
          paymentMethods.filter(paymentMethod =>
            token.payment_methods.some(payment_method => payment_method === paymentMethod.type),
          )) ||
          paymentMethods,
      )
    }
  }, [token, id])

  return (
    <div className={styles.container}>
      {isKycPending && (
        <InfoPanel
          className={styles.warning}
          label="Estamos validando tu identidad"
          description="Una vez que este confirmada tu validación de identidad podrás continuar con el proceso"
          type="warning"
        />
      )}
      <span className={styles.title}>{t('investment.paymentMethod.selectPaymentMethod')}</span>
      {!!paymentMethodsFiltered?.length &&
        paymentMethodsFiltered.map((paymentMethod: IPaymentMethod, index: number) => (
          <div
            key={index}
            className={styles.paymentMethodContainer}
            onClick={() => {
              if (paymentMethod.comingSoon) return
              if (!isKycPending) {
                setActivePaymentMethod({
                  key: paymentMethod.key,
                  type: paymentMethod.type,
                  icon: paymentMethods.find(method => method.type === paymentMethod.type)?.icon,
                  iconClassName: paymentMethod.iconClassName,
                })
                saveInvestmentStorage({
                  paymentMethodTypeSelected: paymentMethod.type,
                })
                nextStep()
              }
            }}>
            {index + 1 === paymentMethodsFiltered?.length &&
              paymentMethod.type === PAYMENT_METHOD.wallet && (
                <span className={styles.or}>{t('investment.paymentMethod.orAccessWith')}</span>
              )}
            <div
              className={classNames(styles.paymentMethod, paymentMethod.className, {
                [styles.disable]: isKycPending,
                [styles.comingSoon]: !!paymentMethod.comingSoon,
              })}>
              <div className={styles.box}>
                <div className={classNames(styles.circle, paymentMethod.iconClassName)}>
                  {paymentMethod.icon}
                </div>
                <div className={classNames(styles.textContainer, paymentMethod.className)}>
                  <span className={styles.name}>
                    {t(`investment.paymentMethod.${paymentMethod.key}`)}
                  </span>
                  {!!paymentMethod.description && (
                    <span
                      className={classNames(styles.description, {[styles.disable]: isKycPending})}>
                      {(!!paymentMethod.comingSoon && 'COMING SOON') || paymentMethod.description}
                    </span>
                  )}
                  {!!paymentMethod.descriptionIcons && paymentMethod.descriptionIcons}
                </div>
                <Arrow className={styles.arrow} />
              </div>
            </div>
            {!!paymentMethod.linkDescription && (
              <div className={styles.linkDescription}>
                {paymentMethod.linkDescription}
                <span onClick={() => console.log(paymentMethod.link)}>
                  {paymentMethod.linkLabel}
                </span>
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

export default withTranslation()(PaymentMethod)
