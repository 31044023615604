import {ReactComponent as Gallery} from '../assets/icons/gallery.svg'
import {ReactComponent as Maps} from '../assets/icons/maps.svg'

export const GALLERY_KEY = 'gallery'
export const MAP_KEY = 'map'
export const DRONE_KEY = 'drone'
export const AUGMENTED_REALITY_INITIALS_KEY = 'augmentedRealityInitials'
export const METAVERSE_KEY = 'metaverse'

export const navigatorButtons = [
  {
    key: GALLERY_KEY,
    labelKey: `item.navigator.${GALLERY_KEY}`,
    icon: <Gallery />,
  },
  {
    key: MAP_KEY,
    labelKey: `item.navigator.${MAP_KEY}`,
    icon: <Maps />,
  },
  {
    key: DRONE_KEY,
    labelKey: `item.navigator.${DRONE_KEY}`,
    icon: <Maps />,
    disabled: true,
  },
  {
    key: '360',
    label: '360º',
    icon: <Maps />,
    disabled: true,
  },
  {
    key: AUGMENTED_REALITY_INITIALS_KEY,
    labelKey: `item.navigator.${AUGMENTED_REALITY_INITIALS_KEY}`,
    icon: <Maps />,
    disabled: true,
  },
  {
    key: METAVERSE_KEY,
    labelKey: `item.navigator.${METAVERSE_KEY}`,
    icon: <Maps />,
    disabled: true,
  },
]
